import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Table,
  Tag,
  Avatar,
  Modal,
  Upload,
  Button,
  Popconfirm
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { faClipboardCheck, faEye, faPencil, faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'antd/es/typography/Link';
import { userAtom, notificationsAtom, userPrivilagesAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H4 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import {
  incidentalsSubmissionStatus
} from '../../../utils';
import {
  incidentalsSubmissionStatusToString,
  toCurrency
} from '../../../functions';
import Select from '../../shared-components/form/Select';
import CustomButton from '../../shared-components/buttons/Button';
import Form from '../../shared-components/form/Form';
import DateSelector from '../../shared-components/form/DatePicker';
import Input from '../../shared-components/form/Input';

function TransdevIncidentals() {
  const { t, i18n } = useTranslation();
  const statusFilters = [
    {
      key: 'allfilters',
      value: '',
      text: t('all'),
    },
    {
      key: 'pendingfilter',
      value: 'PENDING',
      text: t('pending'),
    },
    {
      key: 'approvedfilter',
      value: 'APPROVED',
      text: t('approved'),
    },
    {
      key: 'rejectedfilter',
      value: 'REJECTED',
      text: t('rejected'),
    },
  ];
  const types = [
    {
      key: 'alltypes',
      value: '',
      text: t('all'),
    },
    {
      key: 'fixedtype',
      value: 'FIXED',
      text: t('fixed'),
    },
    {
      key: 'credittype',
      value: 'CREDIT',
      text: t('credit'),
    },
  ];

  const beforeApprovalStatuses = [
    { id: 1, name: 'in_review' },
    { id: 2, name: 'needs_more_info' },
    { id: 3, name: 'accepted' },
    { id: 4, name: 'rejected' }
  ];
  const afterApprovalStatuses = [
    { id: 5, name: 'payment_accepted' },
    { id: 6, name: 'payment_rejected' }
  ];

  const { Dragger } = Upload;
  const user = useRecoilValue(userAtom);
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);

  const [indicentals, setIndicentals] = useState([]);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [totalIndicentals, setTotalIndicentals] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  
  const [incidentalTypeFilter, setIncidentalTypeFilter] = useState('');
  const [incidentalPaymentFilter, setIncidentalPaymentFilter] = useState('');
  const [incidentalApprovalFilter, setIncidentalApprovalFilter] = useState('');

  const [fileData, setFileData] = useState(null);
  
  const [uploadingFile, setUploadingFile] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  const draggerProps = {
    name: 'file',
    accept: 'image/*,application/pdf',
    multiple: false,
    showUploadList: true,
    maxCount: 1,
    fileList: fileData ? [fileData] : [],
    beforeUpload: (file) => {
      setFileData(file);
      return false;
    },
    ondrop: (e) => {
    },
    onRemove: (e) => {
      setFileData(null);
    }
  };

  function getIncidentals(selectedPage) {
    if (!selectedDate) {
      return;
    }
    setCurrentPage(selectedPage);
    const startDate = dayjs(selectedDate);
    const endDate = startDate.endOf('month');
    setLoading(true);
    const utcOffset = startDate.utcOffset();
    const startString = startDate.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    const endString = endDate.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    api.post('transdev/incidentals/submissions/all', {
      StartDate: startString,
      EndDate: endString,
      Page: currentPage,
      Size: pageSize,
      TypeFilter: incidentalTypeFilter,
      PaymentFilter: incidentalPaymentFilter,
      ApprovalFilter: incidentalApprovalFilter
    })
    .then((response) => {
      setLoading(false);
      if (response.data.success) {
        setIndicentals(response.data.data.submissions);
        setTotalIndicentals(response.data.data.total);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoading(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getAttachments(incidentalsId) {
    setLoadingAttachments(true);
    api.get(`transdev/incidentals/submission/attachments/${incidentalsId}`)
      .then((response) => {
        setLoadingAttachments(false);
        if (response.data.success) {
          setAttachments(response.data.data);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoadingAttachments(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function getTextStatusColor(status) {
    if (status === 'APPROVED') {
      return 'green';
    }
    if (status === 'REJECTED') {
      return 'red';
    }
    return ''
  }

  function getStatusColor(status) {
    if (status === 3 || status === 5) {
      return 'green';
    }
    if (status === 4 || status === 6) {
      return 'red';
    }
    if (status === 2) {
      return 'orange';
    }
    return ''
  }

  function getTextStatusString(status) {
    if (status === 'APPROVED') {
      return t('approved');
    }
    if (status === 'REJECTED') {
      return t('rejected');
    }
    return t('pending')
  }

  function getTypeToString(type) {
    const filtered = _.filter(types, (ty) => ty.value === type);
    if (filtered?.length > 0) {
      return filtered[0].text;
    }
    return '';
  }

  function updateSubmission(formData) {
    setSubmissionLoading(true);
    api.put('transdev/incidentals/submission', {
      ...formData,
      resubmit: formData.resubmit ?? null,
      date: dayjs(formData.date)
    })
      .then((response) => {
        setSubmissionLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
        getIncidentals(currentPage);
      })
      .catch((error) => {
        setSubmissionLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function createSubmission(formData) {
    setSubmissionLoading(true);
    api.post('transdev/incidentals/submission', {
      ...formData,
      date: dayjs(formData.date)
    })
      .then((response) => {
        setSubmissionLoading(false);
        console.log('response.data', response.data);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
          submissionData.setFieldValue('id', response.data.data.id);
          submissionData.setFieldValue('submitter', response.data.data.submitter);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
        getIncidentals(currentPage);
      })
      .catch((error) => {
        setSubmissionLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function reviewSubmission(formData) {
    setSubmissionLoading(true);
    api.post('transdev/incidentals/submission/review', formData)
      .then((response) => {
        setSubmissionLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
        getIncidentals(currentPage);
      })
      .catch((error) => {
        setSubmissionLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function uploadFile() {
    const formdata = new FormData();
    formdata.append('file', fileData);
    formdata.append('submissionId', submissionData?.values?.id);
    formdata.append('uploadedFileName', fileData.name);
    setUploadingFile(true);
    api.post('transdev/incidentals/submission/attachment', formdata)
      .then((response) => {
        setUploadingFile(false);
        if (response.data.success) {
          setFileData(null);
          getAttachments(submissionData?.values?.id);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              }
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('uploaded_file_successfully'),
                error: false,
              }
            ]);
          }
        } else if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: t('error_uploading_file'),
              error: true,
            }
          ]);
        }
        setFileData(null);
      })
      .catch((error) => {
        setUploadingFile(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function deleteFile(id) {
    api.delete(`transdev/incidentals/submission/attachment/${id}`)
      .then((response) => {
        if (response.data.success) {
          getAttachments(submissionData?.values?.id);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              }
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('file_removed_successfully'),
                error: false,
              }
            ]);
          }
        } else if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: t('error_deleting_file'),
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setUploadingFile(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  const submissionData = useFormik({
    initialValues: {
      id: null,
      resubmit: true,
      type: 'FIXED',
      date: dayjs().toString(),
      description: '',
      amount: 0,
      approvalStatus: 'PENDING',
      paymentStatus: 'PENDING',
      month: dayjs().month() + 1,
      year: dayjs().year(),
      status: 0,
      approvalDate: null,
      paymentApprovalDate: null,
      submittedById: null,
      approvedById: null,
      paymentApprovedById: null,
      reviewerComments: '',
      submitter: null,
      approver: null,
      paymentApprover: null,
      isNew: true,
      isReadOnly: false,
      openModal: false
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required(t('description_required')),
      amount: Yup.number().positive().min(0.01).required(t('positive_amount_required')),
    }),
    onSubmit: (values, { setSubmitting }) =>
      values.id !== null ? updateSubmission(values) : createSubmission(values),
  });

  const submissionDataForReview = useFormik({
    initialValues: {
      id: null,
      resubmit: true,
      type: 'FIXED',
      date: dayjs().toString(),
      description: '',
      amount: 0,
      approvalStatus: 'PENDING',
      paymentStatus: 'PENDING',
      month: dayjs().month() + 1,
      year: dayjs().year(),
      status: 0,
      originalStatus: 0,
      approvalDate: null,
      paymentApprovalDate: null,
      submittedById: null,
      approvedById: null,
      paymentApprovedById: null,
      reviewerComments: '',
      submitter: null,
      approver: null,
      paymentApprover: null,
      openModal: false
    },
    validationSchema: Yup.object().shape({
      status: Yup.number().positive().min(2, t('select_valid_status'))
      .required(t('select_valid_status'))
      .test('statusCheck', t('select_valid_status'), (val) => val !== submissionDataForReview.values.originalStatus),
    }),
    onSubmit: (values, { setSubmitting }) => reviewSubmission(values),
  });

  useEffect(() => {
    setSelectedDate(dayjs().format('MMM YYYY'));
  }, []);

  useEffect(() => {
    getIncidentals(0);
  }, [pageSize, incidentalPaymentFilter, incidentalTypeFilter,
    incidentalApprovalFilter, selectedDate]);

  return (
    <MainPage>
      <H1 text={`Transdev - ${t('incidentals')}`} />
      <div>
        <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-2">
          <Select
            className="col-span-1 mt-2"
            disabled={loading}
            label={t('type')}
            id="typefilter"
            name="typefilter"
            value={incidentalTypeFilter}
            onChange={(obj) => setIncidentalTypeFilter(obj.target.value)}
            options={types}
          />
          <Select
            className="col-span-1 mt-2"
            disabled={loading}
            label={t('approval_status')}
            id="approvalfilter"
            name="approvalfilter"
            value={incidentalApprovalFilter}
            onChange={(obj) => setIncidentalApprovalFilter(obj.target.value)}
            options={statusFilters}
          />
          <Select
            className="col-span-1 mt-2"
            disabled={loading}
            label={t('payment_status')}
            id="paymentfilter"
            name="paymentfilter"
            value={incidentalPaymentFilter}
            onChange={(obj) => setIncidentalPaymentFilter(obj.target.value)}
            options={statusFilters}
          />
          <div className="col-span-1">
            <DatePicker
              disabled={loading}
              picker="month"
              className="w-full h-11 mt-2 bg-transparent"
              value={dayjs(selectedDate)}
              onChange={(obj) => {
                setSelectedDate(obj.format('MMM YYYY'));
              }}
              format="MMM YYYY"
            />
          </div>
          <CustomButton
            className="bg-primaryColor col-span-1 md:col-span-2 lg:col-start-4 lg:col-span-1 xl:col-start-5 mt-2"
            textColor="text-primaryTextColor"
            loaderColor="bg-primaryTextColor"
            borderColor="border-primaryBorderColor"
            loading={loading}
            text={t('refresh')}
            onClick={() => getIncidentals(currentPage)}
          />
        </div>
        <div className="mt-2">
          <Table
            bordered
            locale={{
              emptyText: t('no_data')
            }}
            title={() => (
              <div className="flex">
                <CustomButton
                  className="bg-primaryColor px-2 ml-auto"
                  textColor="text-primaryTextColor"
                  loaderColor="bg-primaryTextColor"
                  borderColor="border-primaryBorderColor"
                  text={(
                    <div>
                      <FontAwesomeIcon className="mr-2" icon={faPlus} />
                      {t('create_new')}
                    </div>
                  )}
                  loading={loading}
                  onClick={() => {
                    submissionData.setValues({
                      id: null,
                      resubmit: false,
                      type: 'FIXED',
                      date: dayjs().toString(),
                      description: '',
                      amount: 0,
                      approvalStatus: 'PENDING',
                      paymentStatus: 'PENDING',
                      month: dayjs().month() + 1,
                      year: dayjs().year(),
                      status: 0,
                      approvalDate: null,
                      paymentApprovalDate: null,
                      submittedById: null,
                      approvedById: null,
                      paymentApprovedById: null,
                      reviewerComments: '',
                      submitter: null,
                      approver: null,
                      paymentApprover: null,
                      isNew: true,
                      isReadOnly: false,
                      openModal: true
                    });
                    setAttachments([]);
                  }}
                />
              </div>
            )}
            rowKey="id"
            loading={loading}
            className="mt-2"
            pagination={{
              pageSize,
              showSizeChanger: true,
              current: currentPage + 1,
              total: totalIndicentals,
              onChange: (page, size) => {
                if (page - 1 !== currentPage) {
                  setCurrentPage(page - 1);
                  getIncidentals(page - 1);
                } else {
                  setPageSize(size);
                }
              }
            }}
            dataSource={indicentals}
            columns={[
              {
                title: t('description'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.description}</span>
                ),
              },
              {
                title: t('submitted_on'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{dayjs(row.createdAt).format('MMM DD, YYYY hh:mm A')}</span>
                ),
              },
              {
                title: t('submitted_by'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                <>
                  {row.submitter ?
                    <div>
                      <Avatar src={row.submitter?.avatar} />
                      <div>{row.submitter?.completeName}</div>
                    </div> : <span>-</span>}
                </>),
              },
              {
                title: t('status'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  // <Tag color={getStatusColor(row.status)}>
                  //   {t(incidentalsSubmissionStatusToString(row.status))}
                  // </Tag>
                  <Tag color={getStatusColor(row.status)}>
                    {t(incidentalsSubmissionStatusToString(row.status))}
                  </Tag>
                ),
              },
              {
                title: t('approval_status'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <Tag color={getTextStatusColor(row.approvalStatus)}>
                    {getTextStatusString(row.approvalStatus)}
                  </Tag>
                ),
              },
              {
                title: t('payment_status'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <Tag color={getTextStatusColor(row.paymentStatus)}>
                    {getTextStatusString(row.paymentStatus)}
                  </Tag>
                ),
              },
              {
                title: t('attachments'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.attachments?.length ?? '-'}</span>
                ),
              },
              {
                title: t('actions'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <div className="flex justify-center space-x-2">
                    {user.id === row.submittedById && (row.status === 0 || row.status === 2) && (
                      <CustomButton
                        className="bg-primaryColor px-2"
                        textColor="text-primaryTextColor"
                        loaderColor="bg-primaryTextColor"
                        borderColor="border-primaryBorderColor"
                        text={(
                          <div>
                            <FontAwesomeIcon className="mr-2" icon={faPencil} />
                            {t('edit')}
                          </div>
                        )}
                        loading={loading}
                        onClick={() => {
                          submissionData.setValues({
                            id: row.id,
                            resubmit: false,
                            type: row.type,
                            date: row.date,
                            description: row.description ?? '',
                            amount: row.amount,
                            approvalStatus: row.approvalStatus,
                            paymentStatus: row.paymentStatus,
                            month: row.month,
                            year: row.year,
                            status: row.status,
                            approvalDate: row.approvalDate,
                            paymentApprovalDate: row.paymentApprovalDate,
                            submittedById: row.submittedById,
                            approvedById: row.approvedById,
                            paymentApprovedById: row.paymentApprovedById,
                            reviewerComments: row.reviewerComments ?? '',
                            submitter: row.submitter,
                            approver: row.approver,
                            paymentApprover: row.paymentApprover,
                            isNew: false,
                            isReadOnly: false,
                            openModal: true
                          });
                          setAttachments([]);
                          getAttachments(row.id);
                        }}
                      />
                    )}
                    {(user.id !== row.submittedById || user.id === row.submittedById ||
                      ((row.status === 1 && !userPrivilages.hasOperatorManagerPrivilages) ||
                      row.status >= 3 && !userPrivilages.hasOperatorManagerPrivilages)) && (
                      <CustomButton
                        className="bg-primaryColor px-2"
                        textColor="text-primaryTextColor"
                        loaderColor="bg-primaryTextColor"
                        borderColor="border-primaryBorderColor"
                        text={(
                          <div>
                            <FontAwesomeIcon className="mr-2" icon={faEye} />
                            {t('view')}
                          </div>
                        )}
                        loading={loading}
                        onClick={() => {
                          submissionData.setValues({
                            id: row.id,
                            resubmit: false,
                            type: row.type,
                            date: row.date,
                            description: row.description ?? '',
                            amount: row.amount,
                            approvalStatus: row.approvalStatus,
                            paymentStatus: row.paymentStatus,
                            month: row.month,
                            year: row.year,
                            status: row.status,
                            approvalDate: row.approvalDate,
                            paymentApprovalDate: row.paymentApprovalDate,
                            submittedById: row.submittedById,
                            approvedById: row.approvedById,
                            paymentApprovedById: row.paymentApprovedById,
                            reviewerComments: row.reviewerComments ?? '',
                            submitter: row.submitter,
                            approver: row.approver,
                            paymentApprover: row.paymentApprover,
                            isNew: false,
                            isReadOnly: true,
                            openModal: true
                          });
                          setAttachments([]);
                          getAttachments(row.id);
                        }}
                      />
                    )}
                    {((row.status === 0 || row.status === 1 ||
                       row.status === 3 || row.status === 4) &&
                      userPrivilages.hasOperatorManagerPrivilages) && (
                      <CustomButton
                        className="bg-primaryColor px-2"
                        textColor="text-primaryTextColor"
                        loaderColor="bg-primaryTextColor"
                        borderColor="border-primaryBorderColor"
                        text={(
                          <div>
                            <FontAwesomeIcon className="mr-2" icon={faClipboardCheck} />
                            {t('review')}
                          </div>
                        )}
                        loading={loading}
                        onClick={() => {
                          if (row.status === 0) {
                            setSubmissionLoading(true);
                            api.post('transdev/incidentals/submission/review', {
                              ...row,
                              status: 1
                            })
                              .then((response) => {
                                setSubmissionLoading(false);
                                if (response.data.success) {
                                  const tempSubmissions = [...indicentals];
                                  for (let i = 0; i < tempSubmissions.length; i++) {
                                    if (tempSubmissions[i].id === row.id) {
                                      tempSubmissions[i].status = 1;
                                      submissionDataForReview.setFieldValue('status', 1);
                                      submissionDataForReview.setFieldValue('originalStatus', 1);
                                    }
                                  }
                                  setIndicentals(tempSubmissions);
                                }
                              })
                              .catch((error) => {
                                setSubmissionLoading(false);
                              });
                          }

                          submissionDataForReview.setValues({
                            id: row.id,
                            resubmit: false,
                            type: row.type,
                            date: row.date,
                            description: row.description ?? '',
                            amount: row.amount,
                            approvalStatus: row.approvalStatus,
                            paymentStatus: row.paymentStatus,
                            month: row.month,
                            year: row.year,
                            status: row.status,
                            originalStatus: row.status,
                            approvalDate: row.approvalDate,
                            paymentApprovalDate: row.paymentApprovalDate,
                            submittedById: row.submittedById,
                            approvedById: row.approvedById,
                            paymentApprovedById: row.paymentApprovedById,
                            reviewerComments: row.reviewerComments ?? '',
                            submitter: row.submitter,
                            approver: row.approver,
                            paymentApprover: row.paymentApprover,
                            openModal: true
                          });
                          setAttachments([]);
                          getAttachments(row.id);
                        }}
                      />
                    )}
                  </div>
                )
              }
            ]}
          />
        </div>
      </div>
      <Modal
        key="selectedSubmissionModal"
        title={submissionData?.values?.id ? (submissionData?.values?.isReadOnly ? t('view_submission') : t('edit_submission')) : t('new_submission')}
        open={submissionData?.values?.openModal}
        onCancel={() => submissionData.setFieldValue('openModal', false)}
        centered
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <CustomButton
              key="closeSelectedSubmissionModal"
              type="primary"
              danger
              className="bg-rose-500 px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              disabled={submissionLoading}
              onClick={() => submissionData.setFieldValue('openModal', false)}
              text={t('cancel')}
            />
            {!submissionData?.values?.isReadOnly && submissionData?.values?.id && (
              <CustomButton
                className="bg-primaryColor px-4"
                textColor="text-primaryTextColor"
                loaderColor="bg-primaryTextColor"
                borderColor="border-primaryBorderColor"
                loading={submissionLoading}
                text={t('update')}
                onClick={submissionData.submitForm}
              />
            )}
            {!submissionData?.values?.isReadOnly &&
              submissionData?.values?.id && submissionData.values?.status === 2 && (
              <CustomButton
                className="bg-primaryColor px-4"
                textColor="text-primaryTextColor"
                loaderColor="bg-primaryTextColor"
                borderColor="border-primaryBorderColor"
                loading={submissionLoading}
                text={t('resubmit')}
                onClick={() => {
                  submissionData.setFieldValue('resubmit', true);
                  setTimeout(() => {
                    submissionData.submitForm();
                  }, 1000);
                }}
              />
            )}
            {!submissionData?.values?.id && submissionData?.values?.isNew && (
              <CustomButton
                className="bg-primaryColor px-4"
                textColor="text-primaryTextColor"
                loaderColor="bg-primaryTextColor"
                borderColor="border-primaryBorderColor"
                loading={submissionLoading}
                text={t('submit')}
                onClick={submissionData.submitForm}
              />
            )}
          </div>
        ]}
      >
        <Form className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full overflow-y-auto">
          {submissionData?.values?.submitter &&
            submissionData?.values?.submitter?.id !== user?.id && (
            <div className="md:col-span-2 space-x-1 flex">
              <strong className="mt-1">{t('submitted_by')}:</strong>
              <Avatar src={submissionData?.values?.submitter?.avatar} />
              <div className="mt-1">{submissionData?.values?.submitter?.completeName}</div>
            </div>
          )}
          {submissionData?.values?.isReadOnly && (
            <>
              <div className="md:col-span-2">
                <strong>{t('description')}</strong>
                <p>{submissionData?.values.description}</p>
              </div>
              <div>
                <strong>{t('amount')}</strong>
                <p>{toCurrency(submissionData?.values.amount)}</p>
              </div>
              <div>
                <strong>{t('date')}</strong>
                <p>{dayjs(submissionData?.values.date).format('MMM d, 2024')}</p>
              </div>

            </>
          )}
          {!submissionData?.values?.isReadOnly && (
            <>
              <Select
                className="md:col-span-2 mt-2"
                disabled={loading}
                label={t('type')}
                id="submissiontype"
                name="submissiontype"
                value={submissionData?.values.type}
                onChange={(obj) => submissionData.setFieldValue('type', obj.target.value)}
                options={_.filter(types, (type) => type.value?.length > 0)}
              />
              <Input
                label={t('description')}
                onChange={submissionData.handleChange}
                value={submissionData?.values.description}
                disabled={submissionData?.values?.isReadOnly || submissionLoading}
                required
                className="md:col-span-2"
                type="text"
                name="description"
                id="description"
                error={
                  submissionData.errors.description &&
                  submissionData.touched.description &&
                  submissionData.errors.description
                }
              />
              <Input
                label={t('amount')}
                onChange={(obj) => {
                  // submissionData.handleChange
                  submissionData.setFieldValue('amount', obj.target.value);
                }}
                value={submissionData?.values.amount}
                required
                disabled={submissionData?.values?.isReadOnly || submissionLoading}
                min={0}
                step={1}
                type="number"
                name="amount"
                id="amount"
                error={
                  submissionData.errors.amount &&
                  submissionData.touched.amount &&
                  submissionData.errors.amount
                }
              />
              <DateSelector
                label={t('date')}
                maxDate={dayjs().toDate()}
                value={Date.parse(submissionData.values.date)}
                disabled={submissionData.values.isReadOnly || submissionLoading}
                error={
                  submissionData.errors.date &&
                  submissionData.touched.date &&
                  submissionData.errors.date
                }
                onChange={(date) => submissionData.setFieldValue('date', date.toString())}
              />
            </>
          )}
          {submissionData?.values.id && (
            <div className="md:col-span-2">
              <div className="h-divider bg-gray-300 mb-4" />
              {!submissionData?.values?.isReadOnly && (
                <>
                  <div>
                    <Dragger className="h-28" disabled={uploadingFile} {...draggerProps}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        {t('click_or_drag_content')}
                      </p>
                    </Dragger>
                  </div>
                  <div className="grid grid-cols-1 mt-2 gap-2">
                    <CustomButton
                      className="bg-primaryColor md:w-2/3 mx-auto"
                      textColor="text-primaryTextColor"
                      loaderColor="bg-primaryTextColor"
                      borderColor="border-primaryBorderColor"
                      loading={uploadingFile}
                      disabled={!fileData}
                      text={t('upload')}
                      onClick={() => uploadFile()}
                    />
                  </div>
                </>
              )}

              <H4 className="mt-4" text={t('attachments')} />
              {_.map(attachments, (attachment) => (
                <div className="flex">
                  <Link className="text-lg" target="_blank" href={attachment?.attachmentUrl}>
                    • {attachment?.fileName}
                  </Link>
                  <Popconfirm
                    key="deleteattachment"
                    title={t('delete_attachment_prompt')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() => deleteFile(attachment?.id)}
                  >
                    <Button
                      size="small"
                      type="primary"
                      className="ml-2 mt-1 text-red-600 hover:text-white bg-transparent hover:bg-red-600"
                    >
                      <FontAwesomeIcon icon={faTrash} className="text-xs" />
                    </Button>
                  </Popconfirm>
                </div>
              ))}
              {attachments?.length === 0 && (
                <p className="italic text-gray-400">{t('no_attachments')}</p>
              )}

              {submissionData?.values?.approver && (
                <>
                  <div className="h-divider bg-gray-300 mt-4" />
                  <H4 className="mt-4" text={t('review')} />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="mt-1">
                      <div className="font-bold">{t('reviewed_by')}:</div>
                      <div className="space-x-1 flex">
                        <Avatar src={submissionData?.values?.approver?.avatar} />
                        <div className="mt-1">{submissionData?.values?.approver?.completeName}</div>
                      </div>
                    </div>
                    <div className="flex space-x-1">
                      <strong className="mt-1">{t('approval_status')}:</strong>
                      <p className="mt-1">
                        {getTextStatusString(submissionData?.values.approvalStatus)}
                      </p>
                    </div>
                    {submissionData?.values.paymentApprover && (
                      <>
                        <div className="mt-2">
                          <div className="font-bold">{t('payment_reviewed_by')}:</div>
                          <div className="space-x-1 flex">
                            <Avatar src={submissionData?.values?.paymentApprover?.avatar} />
                            <div className="mt-1">{submissionData?.values?.paymentApprover?.completeName}</div>
                          </div>
                        </div>
                        <div className="flex space-x-1 mt-2">
                          <strong className="mt-1">{t('payment_status')}:</strong>
                          <p className="mt-1">
                            {getTextStatusString(submissionData?.values.paymentStatus)}
                          </p>
                        </div> 
                      </>
                    )}
                    <div className="md:col-span-2 mt-2">
                      <strong>{t('reviewer_comments')}:</strong>
                      {submissionData?.values.reviewerComments && (
                        <p>{submissionData?.values.reviewerComments}</p>
                      )}
                      {!submissionData?.values.reviewerComments && (
                        <p className="italic text-gray-400">{t('no_comments')}</p>
                      )}
                    </div> 
                  </div>
                </>
              )}
            </div>
          )}
        </Form>
      </Modal>

      <Modal
        key="selectedSubmissionModalForReview"
        title={t('review_submission')}
        open={submissionDataForReview?.values?.openModal}
        onCancel={() => submissionDataForReview.setFieldValue('openModal', false)}
        centered
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <CustomButton
              key="closeSelectedSubmissionModal"
              type="primary"
              danger
              className="bg-rose-500 px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              disabled={submissionLoading}
              onClick={() => submissionDataForReview.setFieldValue('openModal', false)}
              text={t('cancel')}
            />
            <CustomButton
              className="bg-primaryColor px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              loading={submissionLoading}
              text={t('submit')}
              onClick={submissionDataForReview.submitForm}
            />
          </div>
        ]}
      >
        <Form className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full overflow-y-auto">
          <div className="md:col-span-2 space-x-1 flex">
            <strong className="mt-1">{t('submitted_by')}:</strong>
            <Avatar src={submissionDataForReview?.values?.submitter?.avatar} />
            <div className="mt-1">{submissionDataForReview?.values?.submitter?.completeName}</div>
          </div>
          <div className="md:col-span-2">
            <strong>{t('type')}</strong>
            <p>{getTypeToString(submissionDataForReview?.values.type)}</p>
          </div>
          <div>
            <strong>{t('approval_status')}</strong>
            <p>{getTextStatusString(submissionDataForReview?.values.approvalStatus)}</p>
          </div>
          <div>
            <strong>{t('payment_status')}</strong>
            <p>{getTextStatusString(submissionDataForReview?.values.paymentStatus)}</p>
          </div>
          <div className="md:col-span-2">
            <strong>{t('description')}</strong>
            <p>{submissionDataForReview?.values.description}</p>
          </div>
          <div>
            <strong>{t('amount')}</strong>
            <p>{toCurrency(submissionDataForReview?.values.amount)}</p>
          </div>
          <div>
            <strong>{t('date')}</strong>
            <p>{dayjs(submissionDataForReview?.values.date).format('MMM d, 2024')}</p>
          </div>
          
          <div className="h-divider bg-gray-300 md:col-span-2" />
          <div className="md:col-span-2">
            <H4 text={t('attachments')} />
            {_.map(attachments, (attachment) => (
              <div className="flex">
                <Link className="text-lg" target="_blank" href={attachment?.attachmentUrl}>
                  • {attachment?.fileName}
                </Link>
              </div>
            ))}
          </div>
          <div className="h-divider bg-gray-300 mb-4 md:col-span-2" />
          <Select
            label={t('status')}
            className="md:col-span-2"
            id="submissionstatus"
            name="submissionstatus"
            disabled={uploadingFile}
            value={submissionDataForReview?.values.status}
            onChange={(obj) => submissionDataForReview.setFieldValue('status', parseInt(obj.target.value, 10))}
            options={_.map(submissionDataForReview?.values.originalStatus < 3 ?
              beforeApprovalStatuses : afterApprovalStatuses, (i) => ({
              key: i.id,
              value: i.id,
              text: t(i.name),
              disabled: submissionDataForReview?.values.originalStatus === i.id
            }))}
            error={
              submissionDataForReview.errors.status &&
              submissionDataForReview.touched.status &&
              submissionDataForReview.errors.status
            }
          />
          <Input
            label={t('reviewer_comments')}
            onChange={submissionDataForReview.handleChange}
            value={submissionDataForReview?.values.reviewerComments}
            disabled={submissionLoading}
            className="md:col-span-2"
            type="text"
            name="reviewerComments"
            id="reviewerComments"
            error={
              submissionDataForReview.errors.reviewerComments &&
              submissionDataForReview.touched.reviewerComments &&
              submissionDataForReview.errors.reviewerComments
            }
          />

          {submissionDataForReview?.values?.approver && (
            <div className="md:col-span-2 mt-2">
              <div className="font-bold">{t('reviewed_by')}:</div>
              <div className="flex space-x-1">
                <Avatar src={submissionDataForReview?.values?.approver?.avatar} />
                <div className="mt-1">{submissionDataForReview?.values?.approver?.completeName}</div>
              </div>
            </div>
          )}
          {submissionDataForReview?.values?.paymentApprover && (
           <div className="md:col-span-2 mt-2">
             <div className="font-bold">{t('payment_reviewed_by')}:</div>
             <div className="flex space-x-1">
               <Avatar src={submissionDataForReview?.values?.paymentApprover?.avatar} />
               <div className="mt-1">{submissionDataForReview?.values?.paymentApprover?.completeName}</div>
             </div>
           </div>
          )}
        </Form>
      </Modal>
    </MainPage>
  );
}

export default TransdevIncidentals;
