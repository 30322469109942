import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Upload
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
import { AnimatePresence, motion } from 'framer-motion';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H4 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import { Spinner } from '../../shared-components/Spinner';
import { backdropVariants, frequencies } from '../../../utils';
import Select from '../../shared-components/form/Select';
import Button from '../../shared-components/buttons/Button';

function DocumentUpload() {
  const { Dragger } = Upload;
  const { RangePicker } = DatePicker;
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [loadingOperators, setLoadingOperators] = useState(true);
  const [loadingRequiredFiles, setLoadingRequiredFiles] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [fileData, setFileData] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const imageDraggerProps = {
    name: 'file',
    accept: '.csv',
    multiple: false,
    showUploadList: true,
    maxCount: 1,
    fileList: fileData ? [fileData] : [],
    beforeUpload: (file) => {
      if (file.type !== 'text/csv') {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: t('wrong_file_type_selected'),
            error: true,
          }
        ]);
      } else {
        setFileData(file);
      }
      return false;
    },
    ondrop: (e) => {
      console.log(e);
    },
    onRemove: (e) => {
      setFileData(null);
    }
  };

  function getOperators() {
    setLoadingOperators(true);
    api.get('operators/user')
    .then((response) => {
      if (response.data.success) {
        setLoadingOperators(false);
        setOperators(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingOperators(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getRequiredFiles() {
    setLoadingRequiredFiles(true);
    api.get(`operators/${selectedOperator?.id}/files/active`)
    .then((response) => {
      setLoadingRequiredFiles(false);
      if (response.data.success) {
        setRequiredFiles(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingRequiredFiles(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function uploadFile() {
    const formdata = new FormData();
    formdata.append('file', fileData);
    formdata.append('requiredFileId', selectedFile?.id);
    formdata.append('frequency', selectedFrequency);
    formdata.append('date', selectedDate ? dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ') : dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
    setUploadingFile(true);
    api.post('operators/files/upload', formdata)
      .then((response) => {
        setUploadingFile(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              }
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('uploaded_file_successfully'),
                error: false,
              }
            ]);
          }
        } else if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: t('error_uploading_file'),
              error: true,
            }
          ]);
        }
        setFileData(null);
      })
      .catch((error) => {
        setUploadingFile(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  useEffect(() => {
    getOperators();
  }, []);

  useEffect(() => {
    if (operators.length > 0) {
      setSelectedOperator(operators[0]);
    } else {
      setSelectedOperator(null);
    }
  }, [operators]);

  useEffect(() => {
    setRequiredFiles([]);
    if (selectedOperator) {
      getRequiredFiles();
    }
  }, [selectedOperator]);

  useEffect(() => {
    if (requiredFiles.length > 0) {
      setSelectedFile(requiredFiles[0]);
    } else {
      setSelectedFile(null);
    }
  }, [requiredFiles]);

  useEffect(() => {
    setSelectedFrequency(0);
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFrequency === 0) {
      setSelectedDate(dayjs().format('MM DD, YYYY'));
    } else if (selectedFrequency === 1) {
      setSelectedDate(dayjs().format('MM DD, YYYY'));
    } else if (selectedFrequency === 2) {
      setSelectedDate(dayjs().format('MMM YYYY'));
    } else if (selectedFrequency === 3) {
      setSelectedDate(dayjs().format('YYYY'));
    } else {
      setSelectedDate('');
    }
  }, [selectedFrequency]);

  return (
    <MainPage>
      <H1 text={t('document_upload')} />
      {loadingOperators && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!loadingOperators && (
        <div>
          <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {operators?.length > 1 && (
              <Select
                className="col-span-1 mt-2"
                label={t('operator')}
                id="operator"
                name="operator"
                disabled={uploadingFile}
                value={selectedOperator?.id}
                onChange={(obj) => {
                  const filter = _.filter(operators, (op) => op.id === obj.target.value);
                  if (filter.length > 0) {
                    setSelectedOperator(filter[0]);
                  }
                }}
                options={_.map(operators, (op) => ({
                  key: op.id,
                  value: op.id,
                  text: i18n.language === 'en' ? op.nameEn : op.nameEs,
                }))}
              />
            )}
            <Select
              className={`${operators?.length > 1 ? 'col-span-1' : 'col-span-2'} mt-2 md:ml-2`}
              label={t('file')}
              id="file"
              name="file"
              disabled={requiredFiles?.length === 0 ||
                        requiredFiles?.length === 1 ||
                        loadingRequiredFiles || uploadingFile}
              value={selectedFile?.id}
              onChange={(obj) => {
                const filter = _.filter(requiredFiles, (file) => file.id === obj.target.value);
                if (filter.length > 0) {
                  setSelectedFile(filter[0]);
                }
              }}
              options={_.map(requiredFiles, (file) => ({
                key: file.id,
                value: file.id,
                text: i18n.language === 'en' ? file.nameEn : file.nameEs,
              }))}
            />
            {selectedFile && (
              <Select
                className="col-span-1 mt-2 lg:ml-2"
                disabled={uploadingFile}
                label={t('frequency')}
                id="frequency"
                name="frequency"
                value={selectedFrequency}
                onChange={(obj) => {
                  setSelectedFrequency(parseInt(obj.target.value, 10));
                }}
                options={_.map(frequencies, (f) => ({
                  key: f.id,
                  value: f.id,
                  text: t(f.name),
                }))}
              />
            )}
            {selectedFile && (
              <div className="col-span-1">
                {selectedFrequency === 0 && (
                  <DatePicker
                    disabled={uploadingFile}
                    className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
                    value={dayjs(selectedDate)}
                    onChange={(obj) => {
                      setSelectedDate(obj.format('MMM DD, YYYY'));
                    }}
                    format="MMM DD, YYYY"
                  />
                )}
                {selectedFrequency === 1 && (
                  <DatePicker
                    disabled={uploadingFile}
                    picker="week"
                    className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
                    value={dayjs(selectedDate)}
                    onChange={(obj) => {
                      setSelectedDate(obj.startOf('week').format('MMM DD, YYYY'));
                    }}
                    format="MMM DD, YYYY"
                  />
                )}
                {selectedFrequency === 2 && (
                  <DatePicker
                    disabled={uploadingFile}
                    picker="month"
                    className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
                    value={dayjs(selectedDate)}
                    onChange={(obj) => {
                      setSelectedDate(obj.format('MMM YYYY'));
                    }}
                    format="MMM YYYY"
                  />
                )}
                {selectedFrequency === 3 && (
                  <DatePicker
                    disabled={uploadingFile}
                    picker="year"
                    className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
                    value={dayjs(selectedDate)}
                    onChange={(obj) => {
                      setSelectedDate(obj.format('YYYY'));
                    }}
                    format="YYYY"
                  />
                )}
              </div>
            )}
          </div>
          <div className="w-full">
            {requiredFiles?.length && !loadingRequiredFiles === 0 && (
              <H4
                text={t('no_required_files')}
                className="mx-auto text-gray-400 text-center mt-32 col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4"
              />
            )}
            {(loadingRequiredFiles) && (
              <div className="w-full h-full flex items-center">
                <Spinner className="mx-auto text-accentColor" big />
              </div>
            )}
            {selectedFile && (
              <div className="mt-6 md:h-52 lg:h-64 col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
                {selectedFile?.filePreviewUrl && (
                  <Link className="text-lg" target="_blank" href={selectedFile?.filePreviewUrl}>
                    {t('click_for_example_file')}
                  </Link>
                )}
                <Dragger disabled={uploadingFile} {...imageDraggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ margin: 10 }}
                  >
                    {t('click_or_drag_content')}
                  </p>
                </Dragger>
              </div>
            )}
            {selectedFile && (
              <div className="w-full flex">
                <Button
                  className="bg-primaryColor mx-auto w-9/12 mt-16"
                  textColor="text-primaryTextColor"
                  loaderColor="bg-primaryTextColor"
                  borderColor="border-primaryBorderColor"
                  loading={uploadingFile}
                  disabled={!fileData}
                  text={t('submit')}
                  onClick={() => uploadFile()}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </MainPage>
  );
}

export default DocumentUpload;
