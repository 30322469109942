/* eslint-disable no-confusing-arrow,react/jsx-curly-newline */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import envConfig from '../envConfig';

function ProtectedRoute({ component: Component, ...rest }) {
  const [user] = useLocalStorage(`${envConfig.StoragePrefix}-user`);
  if (!user) {
    window.location = '/';
  }

  return (
    <Route>
      <Route
        {...rest}
        render={(props) =>
          user ? (
          <Route
            {...rest}
            render={(routeProps) => <Component {...routeProps} />}
          />
          ) : (
          <Redirect
            to={{
            pathname: '/',
            state: { from: props.location },
            }}
          />
          )
        }
      />
    </Route>
  );
}

export default ProtectedRoute;
