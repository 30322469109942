import React from 'react';

export function H1({ text, className }) {
  return (
    <div className={`${className} text-xl md:text-xxl font-bold tracking-tight`}>
      {text}
    </div>
  );
}

export function H2({ text, className }) {
  return (
    <div className={`${className} text-lg md:text-xl font-semibold tracking-tight`}>
      {text}
    </div>
  );
}

export function H3({ text, className }) {
  return (
    <div className={`${className} text-base md:text-lg font-semibold tracking-tight`}>
      {text}
    </div>
  );
}

export function H4({ text, className }) {
  return (
    <div className={`${className} text-sm md:text-base font-semibold tracking-tight`}>
      {text}
    </div>
  );
}
