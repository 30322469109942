import _ from 'lodash';
import dayjs from 'dayjs';
import CryptoJS from 'crypto-js';
import envConfig from './envConfig';
import {
  uploadHistoryFilters,
  fareboxCollectionSubmissionStatus,
  fareboxCollectionSubmissionAttachmentFileTypes,
  storageFileProcessesStatus,
  incidentalsSubmissionStatus
} from './utils'

export const encryptString = (text) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const keyWordArray = CryptoJS.enc.Utf8.parse(envConfig.EncryptionKey);
  const encrypted = CryptoJS.AES.encrypt(text, keyWordArray, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const encryptedData = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);
  return encryptedData;
};

export const animationTransition = {
  duration: 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const fadeInMotion = {
  opacity: 1,
  transition: animationTransition,
};

export const fadeOutMotion = {
  opacity: 0,
  transition: animationTransition,
};

export const toLocalTime = (date) => {
  if (date) {
    return dayjs(date).subtract(4, 'hour');
  }
  return dayjs.utc().subtract(4, 'hour')
};

export function formatNumber(number) {
  if (number) {
    return number.toLocaleString();
  }
  return '';
}

export const percentage = (n, decimalPoints) => {
  let number = n;
  if (number === null || number === undefined) {
    return '0';
  }
  if (number) {
    const wasNegative = number < 0;
    number = parseFloat(number.toString().replace(',', ''));
    if (wasNegative) {
      number = (-1 * number).toFixed(decimalPoints);
    } else {
      number = number.toFixed(decimalPoints);
    }
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const result = parts.join('.');
    if (wasNegative) {
      return `-${result}%`;
    }
    return `${result}%`;
  }
  return '0';
}

export const thousand = (n, decimalPoints) => {
  let number = n;
  if (number === null || number === undefined) {
    return '-';
  }
  if (number) {
    const wasNegative = number < 0;

    let tempDecimal = 0;
    if (decimalPoints) {
      tempDecimal = decimalPoints;
    }
    number = parseFloat(number.toString().replace(',', ''));
    if (!decimalPoints && number % 1 === 0) {
      tempDecimal = 0;
    } else if (!decimalPoints) {
      tempDecimal = 2;
    }
    if (wasNegative) {
      number = (-1 * number).toFixed(tempDecimal);
    } else {
      number = number.toFixed(tempDecimal);
    }
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const result = parts.join('.');
    if (wasNegative) {
      return `-${result}`;
    }
    return `${result}`;
  }
  return '0';
};


export const toCurrency = (number) => {
  try {
    if (!number) {
      return '-';
    }
    const wasNegative = number < 0;
    let fixed = 0;
    if (wasNegative) {
      fixed = (-1 * number).toFixed(2);
    } else {
      fixed = number.toFixed(2);
    }
    if (wasNegative) {
      return `-$${thousand(fixed, 2)}`;
    }
    return `$${thousand(fixed, 2)}`;
  } catch (error) {
    return '-';
  }
};

export const uuidv4 = () =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )

export const uploadHistoryStatusToString = (status) => {
  const filtered = _.filter(uploadHistoryFilters, (s) => (s.id - 1) === status)
  return filtered[0].name;
};

export const fareboxCollectionSubmissionStatusToString = (status) => {
  const filtered = _.filter(fareboxCollectionSubmissionStatus, (s) => s.id === status)
  return filtered[0].name;
};

export const fareboxCollectionSubmissionAttachmentFileTypesToString = (type) => {
  const filtered = _.filter(fareboxCollectionSubmissionAttachmentFileTypes, (s) => s.id === type)
  return filtered[0].name;
};

export const storageFileProcessesStatusToString = (status) => {
  const filtered = _.filter(storageFileProcessesStatus, (s) => s.id === status)
  return filtered[0].name;
}

export const incidentalsSubmissionStatusToString = (status) => {
  const filtered = _.filter(incidentalsSubmissionStatus, (s) => s.id === status)
  return filtered[0].name;
};
