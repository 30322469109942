import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  Table,
  Input,
  Button,
  Select,
  Popconfirm,
  Popover,
  Tag,
  Switch,
  Modal
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
import { arrayMoveImmutable } from 'array-move';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import envConfig from '../../../envConfig';
import { Spinner } from '../../shared-components/Spinner';
import { H1 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import { uuidv4 } from '../../../functions';
import { fileColumnDataType } from '../../../utils';

function Operators() {
  const defaultFile = {
    ordering: 0,
    isActive: false
  };
  const { t } = useTranslation();
  const { Option } = Select;
  const user = useRecoilValue(userAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [operatorData, setOperatorData] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [allowOrdering, setAllowOrdering] = useState(false);

  const fileTableColumns = [
    {
      title: t('column_name'),
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <Input
          disabled={allowOrdering || loadingFiles}
          value={row.name}
          onChange={(obj) => {
            const temp = [...fileColumns];
            temp[index].name = obj.target.value;
            setFileColumns(temp);
          }}
        />
      ),
    },
    {
      title: t('column_type'),
      align: 'center',
      width: 150,
      className: 'text-sm',
      render: (row, object, index) => (
        <Select
          className="w-full"
          disabled={allowOrdering || loadingFiles}
          value={row.type}
          onChange={(obj) => {
            const temp = [...fileColumns];
            temp[index].type = obj;
            setFileColumns(temp);
          }}
        >
          {_.map(fileColumnDataType, (dt) => (
            <Option key={dt.id} value={dt.id}>{t(dt.name)}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <Popconfirm
          key="deletecolumn"
          title={t('delete_column_prompt')}
          okText={t('yes')}
          cancelText={t('no')}
          onConfirm={() => {
            const temp = [...fileColumns];
            temp.splice(index, 1);
            setFileColumns(temp);
          }}
        >
          <Button
            disabled={allowOrdering || loadingFiles}
            key="close"
            type="primary"
            size="small"
            danger
            loading={loading}
          >
            {t('delete')}
          </Button>
        </Popconfirm>
      ),
    },
    {
      title: t('allow_null'),
      align: 'center',
      width: 150,
      className: 'text-sm',
      render: (row, object, index) => (
        <Switch
          disabled={allowOrdering || loadingFiles}
          checked={row?.allowNull}
          onChange={(checked) => {
            const temp = [...fileColumns];
            temp[index].allowNull = checked;
            setFileColumns(temp);
          }}
        />
      ),
    }
  ];

  function getOperators() {
    setLoading(true);
    api.get('operators/all')
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setOperators(response.data.data);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      })
  }

  function getOperatorFiles(id) {
    setLoadingFiles(true);
    api.get(`operators/${id}/files`)
      .then((response) => {
        setLoadingFiles(false);
        if (response.data.success) {
          setFiles(response.data.data);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoadingFiles(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      })
  }

  function create() {
    setLoading(true);
    api.post('operators', {
      NameEn: operatorData.nameEn,
      NameEs: operatorData.nameEs,
      IsActive: operatorData.isActive,
      FilePrefix: operatorData.filePrefix,
      FileContainer: operatorData.fileContainer
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setOperators(response.data.data);
          setShowModal(false);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function edit() {
    setLoading(true);
    api.put('operators', {
      Id: operatorData.id,
      NameEn: operatorData.nameEn,
      NameEs: operatorData.nameEs,
      IsActive: operatorData.isActive,
      FilePrefix: operatorData.filePrefix,
      FileContainer: operatorData.fileContainer
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setOperators(response.data.data);
          setShowModal(false);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function deleteOperator() {
    setLoading(true);
    api.delete(`operators/${operatorData?.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setOperators(response.data.data);
          setShowModal(false);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function deleteFile(id) {
    setLoadingFiles(true);
    api.delete(`operators/files/${id}`)
      .then((response) => {
        setLoadingFiles(false);
        if (response.data.success) {
          setFiles(response.data.data);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoadingFiles(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function createFile() {
    setLoadingFiles(true);
    api.post(`operators/${operatorData?.id}/files`, {
      ...fileData,
      operatorId: operatorData?.id,
      columnsRequired: JSON.stringify(_.map(fileColumns, (c) => ({
        name: c.name,
        type: c.type,
        allowNull: c.allowNull
      })))
    })
      .then((response) => {
        setLoadingFiles(false);
        if (response.data.success) {
          setFiles(response.data.data);
          setSelectedFile(null);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoadingFiles(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function editFile() {
    setLoadingFiles(true);
    api.put(`operators/${operatorData?.id}/files`, {
      ...fileData,
      operatorId: operatorData?.id,
      columnsRequired: JSON.stringify(_.map(fileColumns, (c) => ({
        name: c.name,
        type: c.type,
        allowNull: c.allowNull
      })))
    })
      .then((response) => {
        setLoadingFiles(false);
        if (response.data.success) {
          setFiles(response.data.data);
          setSelectedFile(null);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoadingFiles(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      });
  }

  function onSortEnd(index) {
    const ds = fileColumns;
    let temp = [...fileColumns]
    if (index.oldIndex !== -1) {
      const newData = arrayMoveImmutable(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      temp = [...newData]
      setFileColumns(temp);
    }
  }
  
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const SortItem = SortableElement((props) => <tr {...props} />);
  const SortContainer = SortableContainer((props) => <tbody {...props} />);
  const DraggableContainer = (props) => (
    <SortContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = fileColumns ?? [];
    if (dataSource) {
      const index = dataSource.findIndex(
        (x) => x.id === restProps['data-row-key']
      );
      return <SortItem index={index} {...restProps} />;
    }
    return null;
  };


  useEffect(() => {
    getOperators();
  }, []);

  useEffect(() => {
    if (!showModal) {
      setFiles([]);
    }
  }, [showModal])

  useEffect(() => {
    if (selectedFile) {
      setAllowOrdering(false);
      setFileData({
        ...selectedFile
      });
      setFileColumns(selectedFile.columnsRequired ?
        _.map(JSON.parse(selectedFile.columnsRequired), (c, index) => ({
        id: uuidv4(),
        name: c.name,
        type: c.type,
        allowNull: c.allowNull
      })) : []);
    } else {
      setAllowOrdering(false);
      setFileData(null);
      setFileColumns([]);
    }
  }, [selectedFile]);
  
  return (
    <MainPage>
      <div className="flex">
        <H1 text={t('operators')} />
        <div className="flex ml-auto space-x-2">
          <Button
            key="create"
            size="small"
            type="primary"
            onClick={() => {
              setOperatorData(null);
              setFiles([]);
              setShowModal(true);
            }}
          >
            {t('create')}
          </Button>
          <Button
            key="reload"
            size="small"
            type="primary"
            onClick={() => getOperators()}
          >
            {t('reload')}
          </Button>
        </div>
      </div>
      <Table
        locale={{
          emptyText: t('no_data')
        }}
        className="mt-4"
        dataSource={operators}
        bordered
        pagination={false}
        columns={[
          {
            title: t('active'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{row.isActive ? <Tag color="green">{t('yes')}</Tag> : <Tag color="red">{t('no')}</Tag>}</span>
            ),
          },
          {
            title: t('name_en'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{row.nameEn}</span>
            ),
          },
          {
            title: t('name_es'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{row.nameEs}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setOperatorData(row);
                  setShowModal(true);
                  getOperatorFiles(row.id);
                }}
              >
                {t('edit')}
              </Button>
            ),
          }
        ]}
      />
      <Modal
        title={operatorData?.id ? t('edit_operator') : t('create_operator')}
        open={showModal}
        onCancel={() => {
          setOperatorData(null);
          setShowModal(false);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            {operatorData?.id && (
              <Popconfirm
                key="closepopup"
                title={t('delete_operator_prompt')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => {
                  deleteOperator();
                }}
              >
                <Button
                  key="close"
                  type="primary"
                  danger
                  loading={loading}
                >
                  {t('delete')}
                </Button>
              </Popconfirm>
            )}
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => {
                setOperatorData(null);
                setShowModal(false);
              }}
            >
              {t('close')}
            </Button>
            <Button
              key="ok"
              type="primary"
              disabled={!(operatorData?.nameEn?.length > 0) || !(operatorData?.nameEs?.length > 0)}
              loading={loading}
              onClick={() => {
                if (operatorData?.id) {
                  edit();
                } else {
                  create();
                }
              }}
            >
              {t('submit')}
            </Button>
          </div>
        ]}
      >
        <div className="mt-4 mb-2">
          <strong>{t('active')}</strong>
          <Switch
            disabled={loading}
            style={{ float: 'right' }}
            checked={operatorData?.isActive}
            onChange={(checked) => {
              setOperatorData({
                ...operatorData,
                isActive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name_en')} </strong>
          <Input
            disabled={loading}
            value={operatorData?.nameEn}
            onChange={(obj) => {
              setOperatorData({
                ...operatorData,
                nameEn: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name_es')}</strong>
          <Input
            disabled={loading}
            value={operatorData?.nameEs}
            onChange={(obj) => {
              setOperatorData({
                ...operatorData,
                nameEs: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <Popover content={t('file_container_info')}>
            <strong>
              {t('file_container')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Input
            disabled={loading}
            value={operatorData?.fileContainer}
            onChange={(obj) => {
              setOperatorData({
                ...operatorData,
                fileContainer: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <Popover content={t('file_prefix_info')}>
            <strong>
              {t('file_prefix')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Input
            disabled={loading}
            value={operatorData?.filePrefix}
            onChange={(obj) => {
              setOperatorData({
                ...operatorData,
                filePrefix: obj.target.value
              });
            }}
          />
        </div>

        <div className="flex w-full mt-6">
          <Popover content={t('required_file_uploads_info')}>
            <strong>
              {t('required_file_uploads')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Button
            className="float-right ml-auto"
            size="small"
            onClick={() => setSelectedFile(defaultFile)}
            type="primary"
          >
            {t('add_new')}
          </Button>
        </div>
        <Table
          size="small"
          locale={{
            emptyText: t('no_data')
          }}
          loading={loadingFiles}
          className="mt-2"
          dataSource={files}
          bordered
          pagination={false}
          columns={[
            {
              title: t('active'),
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <span>{row.isActive ? <Tag color="green">{t('yes')}</Tag> : <Tag color="red">{t('no')}</Tag>}</span>
              ),
            },
            {
              title: t('name'),
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <span>{row.fileName}</span>
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <div className="flex w-full">
                  <Button
                    size="small"
                    type="primary"
                    className="mr-1 ml-auto"
                    onClick={() => {
                      setSelectedFile(row);
                    }}
                  >
                    {t('edit')}
                  </Button>
                  <Popconfirm
                    key="deletefileprompt"
                    title={t('delete_file_prompt')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() => {
                      deleteFile(row.id);
                    }}
                  >
                    <Button
                      key="close"
                      className="ml-1 mr-auto"
                      type="primary"
                      size="small"
                      danger
                      loading={loading}
                    >
                      {t('delete')}
                    </Button>
                  </Popconfirm>
                </div>
              ),
            }
          ]}
        />
      </Modal>
      <Modal
        title={fileData?.id ? t('edit_file_requirement') : t('create_file_requirement')}
        open={selectedFile}
        width={750}
        onCancel={() => {
          setSelectedFile(null);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="close"
              type="primary"
              danger
              loading={loadingFiles}
              onClick={() => {
                setSelectedFile(null);
              }}
            >
              {t('close')}
            </Button>
            <Button
              key="ok"
              type="primary"
              disabled={!(fileData?.nameEn?.length > 0) || !(fileData?.nameEn?.length > 0)
                || !(fileData?.fileName?.length > 0) || !(fileData?.internalName?.length > 0)}
              loading={loadingFiles}
              onClick={() => {
                if (fileData?.id) {
                  editFile();
                } else {
                  createFile();
                }
              }}
            >
              {t('submit')}
            </Button>
          </div>
        ]}
      >
        <div className="mt-4 mb-2">
          <strong>{t('active')}</strong>
          <Switch
            disabled={loadingFiles}
            style={{ float: 'right' }}
            checked={fileData?.isActive}
            onChange={(checked) => {
              setFileData({
                ...fileData,
                isActive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('internal_name')} </strong>
          <Input
            disabled={loadingFiles}
            value={fileData?.internalName}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                internalName: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <Popover content={t('file_name_info')}>
            <strong>
              {t('file_name')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Input
            disabled={loadingFiles}
            value={fileData?.fileName}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                fileName: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name_en')} </strong>
          <Input
            disabled={loadingFiles}
            value={fileData?.nameEn}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                nameEn: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name_es')}</strong>
          <Input
            disabled={loadingFiles}
            value={fileData?.nameEs}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                nameEs: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('description_en')} </strong>
          <Input
            disabled={loadingFiles}
            value={fileData?.descriptionEn}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                descriptionEn: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('description_es')}</strong>
          <Input
            disabled={loadingFiles}
            value={fileData?.descriptionEs}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                descriptionEs: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <Popover content={t('file_preview_url_info')}>
            <strong>
              {t('file_preview_url')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Input
            disabled={loadingFiles}
            value={fileData?.filePreviewUrl}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                filePreviewUrl: obj.target.value
              });
            }}
          />
          {fileData?.filePreviewUrl && (
            <Link target="_blank" href={fileData?.filePreviewUrl}>
              {t('preview')}
            </Link>
          )}
        </div>
        <div className="mb-2">
          <Popover content={t('function_url_info')}>
            <strong>
              {t('function_url')} <FontAwesomeIcon className="text-gray-700" icon={faInfoCircle} />
            </strong>
          </Popover>
          <Input
            disabled={loadingFiles}
            value={fileData?.functionUrl}
            onChange={(obj) => {
              setFileData({
                ...fileData,
                functionUrl: obj.target.value
              });
            }}
          />
        </div>
        <div className="space-x-3 flex mt-4">
          <div className="float-right ml-auto space-x-1">
            <strong>{t('sort_columns')}</strong>
            <Switch
              disabled={loading}
              checked={allowOrdering}
              onChange={(checked) => {
                setAllowOrdering(checked);
              }}
            />
          </div>
          <Button
            size="small"
            onClick={() => setFileColumns([...fileColumns, {
              id: uuidv4(),
              name: '',
              type: 0,
              allowNull: false
            }])}
            type="primary"
          >
            {t('add_new')}
          </Button>
        </div>
        <Table
          size="small"
          locale={{
            emptyText: t('no_data')
          }}
          components={allowOrdering ? {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          } : undefined}
          rowKey="id"
          loading={loadingFiles}
          className="mt-2"
          dataSource={fileColumns}
          bordered
          rowClassName="editable-row"
          pagination={false}
          columns={allowOrdering ? [
            {
              title: '',
              dataIndex: 'sort',
              width: 30,
              className: 'drag-visible',
              render: () => <DragHandle />,
            },
            ...fileTableColumns
          ] : fileTableColumns}
        />
      </Modal>
    </MainPage>
  );
}

export default Operators;
