import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Table,
  Avatar,
  Modal
} from 'antd';
import Link from 'antd/es/typography/Link';
import { userAtom, notificationsAtom, userPrivilagesAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H4 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import { Spinner } from '../../shared-components/Spinner';
import Select from '../../shared-components/form/Select';
import Button from '../../shared-components/buttons/Button';
import PopconfirmButton from '../../shared-components/buttons/PopconfirmButton';
import Form from '../../shared-components/form/Form';
import CustomInput from '../../shared-components/form/Input';

function AmaHolidays() {
  const { RangePicker } = DatePicker;
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const [loadingItineraries, setLoadingItineraries] = useState(false);
  const [itineraries, setItineraries] = useState([]);
  const [loadingHolidays, setLoadingHolidays] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [creatingHoliday, setCreatingHoliday] = useState(false);
  const [createHoliday, setCreateHoliday] = useState(false);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  function getItineraries() {
    setLoadingItineraries(true);
    api.get('ama/itineraries')
    .then((response) => {
      setLoadingItineraries(false);
      if (response.data.success) {
        setItineraries(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
        setLoadingItineraries(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getHolidays() {
    setInitialLoad(false);
    setLoadingHolidays(true);
    let url = 'ama/itinerary/holidays';
    if (dateRange) {
      const startDate = dayjs(dateRange.start);
      const endDate = dayjs(dateRange.end);
      const utcOffset = startDate.utcOffset();
      const startDateString = startDate.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
      const endDateDateString = endDate.endOf('month').add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
      url += `?startDate=${startDateString}&endDate=${endDateDateString}`
    }
    api.get(url)
    .then((response) => {
      setLoadingHolidays(false);
      if (response.data.success) {
        setHolidays(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingHolidays(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function create() {
    const date = dayjs(data?.date);
    const utcOffset = date.utcOffset();
    const dateString = date.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    setCreatingHoliday(true);
    api.post('ama/itinerary/holidays', {
      Date: dateString,
      Name: data?.name,
      Itinerary: data?.itinerary
    })
    .then((response) => {
      setCreatingHoliday(false);
      if (response.data.success) {
        setHolidays(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
        setCreatingHoliday(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function deleteHoliday(holiday) {
    const tempHolidays = [...holidays];
    const index = tempHolidays.findIndex((r) => r.id === holiday.id);
    tempHolidays[index].loading = true;
    setHolidays(tempHolidays);
    api.delete(`ama/itinerary/holidays?holidayId=${holiday.id}`)
    .then((response) => {
      if (response.data.success) {
        setHolidays(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  useEffect(() => {
    getItineraries();
    setDateRange({
      start: dayjs().startOf('year').format('MMM, YYYY'),
      end: dayjs().endOf('year').format('MMM, YYYY')
    });
  }, []);

  useEffect(() => {
    getHolidays();
  }, [dateRange]);

  return (
    <MainPage>
      <H1 text={`AMA - ${t('holidays')}`} />
      {initialLoad && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!initialLoad && (
        <>
          <div className="flex space-x-2 float-right mb-2">
            <div>
              <RangePicker
                value={[
                  dayjs(dateRange?.start),
                  dayjs(dateRange?.end)
                ]}
                disabled={loadingHolidays}
                className="w-full h-11 bg-transparent"
                format="MMM, YYYY"
                picker="month"
                onChange={(obj, dateString) => {
                  setDateRange({
                    start: obj[0].format('MMM, YYYY'),
                    end: obj[1].format('MMM, YYYY')
                  })
                }}
              />
            </div>
            <Button
              className="bg-primaryColor"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              text={t('reload')}
              loading={loadingHolidays}
              onClick={() => {
                getHolidays();
              }}
            />
            <Button
              className="bg-primaryColor"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              text={t('create')}
              loading={creatingHoliday}
              onClick={() => {
                setCreateHoliday(true);
                setData({
                  date: dayjs().format('MMM DD, YYYY'),
                  itinerary: itineraries[0],
                  name: ''
                })
              }}
            />
          </div>
          <Table
            bordered
            locale={{
              emptyText: t('no_data')
            }}
            loading={loadingHolidays}
            pagination={false}
            rowKey="id"
            className="mt-2"
            dataSource={holidays}
            columns={[
              {
                title: t('name'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.name}</span>
                ),
              },
              {
                title: t('date'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{dayjs(row.date).format('MMM DD, YYYY')}</span>
                ),
              },
              {
                title: t('itinerary'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.itinerary}</span>
                ),
              },
              {
                title: t('created_by'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                <>
                  {row.createdBy ?
                    <div>
                      <Avatar src={row.createdBy?.avatar} />
                      <div>{row.createdBy?.completeName}</div>
                      <div>{dayjs(row.createdAt).format('MMM DD, YYYY hh:mm A')}</div>
                    </div> : <span>-</span>}
                </>),
              },
              {
                title: '',
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <PopconfirmButton
                    title={t('remove_holiday_prompt')}
                    description={t('remove_holiday_prompt_desc')}
                    okText={t('yes')}
                    onConfirm={() => {
                      deleteHoliday(row)
                    }}
                    cancelText={t('no')}
                    className="bg-primaryColor w-full"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    loading={row.loading}
                    text={t('remove')}
                  />
                ),
              }
            ]}
          />
        </>
      )}
      <Modal
        title={t('create_holiday')}
        open={createHoliday}
        onCancel={() => {
          setCreateHoliday(false);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="closecommentsbutton"
              type="primary"
              danger
              className="bg-rose-500 px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              disabled={creatingHoliday}
              onClick={() => setCreateHoliday(false)}
              text={t('cancel')}
            />
            <Button
              className="bg-primaryColor px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              loading={creatingHoliday}
              text={t('submit')}
              onClick={() => {
                const promise = new Promise(function (resolve, reject) {
                  create();
                  resolve();
                });
                promise.then(() => {
                    setCreateHoliday(false);
                });
              }}
            />
          </div>
        ]}
      >
        <div>
          <Form className="mt-4 mx-auto w-full mb-8 space-y-3">
            <DatePicker
              disabled={creatingHoliday}
              className="w-full h-11 bg-transparent"
              value={dayjs(data?.date)}
              onChange={(obj) => setData({
                ...data,
                date: obj.format('MMM DD, YYYY')
              })}
              format="MMM DD, YYYY"
            />
            <CustomInput
              label={t('name')}
              type="text"
              name="text"
              id="text"
              disabled={creatingHoliday}
              value={data?.name}
              onChange={(obj) => setData({
                ...data,
                name: obj.target.value
              })}
            />
            <Select
              disabled={loadingItineraries || creatingHoliday}
              label={t('itinerary')}
              id="itineraries"
              name="itineraries"
              value={data?.itinerary}
              onChange={(obj) => setData({
                ...data,
                itinerary: obj.target.value
              })}
              options={_.map(itineraries, (s) => ({
                key: s,
                value: s,
                text: s,
              }))}
            />
          </Form>
        </div>
      </Modal>
    </MainPage>
  );
}

export default AmaHolidays;
