import React from 'react';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import Logo from '../logo/Logo';
import { menuSideBarAtom, userAtom } from '../../../atoms/Atoms';
import LanguagePicker from '../language-picker/LanguagePicker';
import envConfig from '../../../envConfig';

function Header() {
  const location = useLocation();
  const [user, setUser] = useRecoilState(userAtom);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuSideBarAtom);
  const hideMenu = location.pathname !== '/' ? _.filter(envConfig.HideMenuLocations, (l) =>
    location.pathname.startsWith(l)).length >= 1 : true;
  const showLogo = _.filter(envConfig.HideHeaderLogo, (l) =>
    location.pathname.startsWith(l)).length >= 1;

  return ( 
    <header className="h-20 py-2 px-4">
      <div className="flex w-full">
        {showLogo && (
          <Logo
            className="w-36 md:w-52 hover:scale-110"
          />
        )}
        <div className="ml-auto flex divider-x">
          <LanguagePicker className="mt-4 hidden md:block" />
          {/* <div className="hidden md:block">
            {user && (
              <>
                TODO:logged in
              </>
            )}
          </div> */}
        </div>
        {!hideMenu && (
          <button
            aria-label="sidemenu"
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden hover:scale-110 text-headerText transform h-10 w-10 px-2 rounded-md text-xl transition duration-200 ease-in-out ring-0 outline-none focus:ring-0 focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} className="text-headerText" />
          </button>
        )}
      </div>
    </header>
  )
} 

export default Header;
