import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { AppConfigurationClient } from '@azure/app-configuration';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Spin,
  Modal
} from 'antd';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import envConfig from '../../../envConfig';
import { Spinner } from '../../shared-components/Spinner';
import { H1 } from '../../shared-components/typography/Title';

function ServerConfig() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const client = new AppConfigurationClient(envConfig.ServerConfigurationString);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [allSettings, setAllSettings] = useState([]);
  const [settings, setSettings] = useState([]);
  const [newSetting, setNewSetting] = useState({});

  function filterSettings(searchTerm) {
    if (searchTerm?.length > 0) {
      const filtered = _.filter(allSettings,
        (s) => s.key.toUpperCase().includes(searchTerm.toUpperCase()));
      setSettings(filtered);
    } else {
      setSettings(allSettings);
    }
  }

  async function getSettings() {
    setLoading(true);
    const tempSettings = [];
    const existingSettings = await client.listConfigurationSettings();
    for await (const setting of existingSettings) {
      tempSettings.push(setting);
    }
    setAllSettings(tempSettings);
    setLoading(false);
  }

  async function updateSetting(setting) {
    const index = settings.findIndex((s) => s.etag === setting.etag);
    const temp = JSON.parse(JSON.stringify(settings));
    temp[index].loading = true;
    setSettings(temp);
    client.setConfigurationSetting({
      key: setting.key,
      value: setting.value
    }).then((response) => {
      const index2 = settings.findIndex((s) => s.etag === setting.etag);
      const index3 = allSettings.findIndex((s) => s.etag === setting.etag);
      const temp2 = JSON.parse(JSON.stringify(settings));
      const temp3 = JSON.parse(JSON.stringify(allSettings));
      temp2[index2].loading = false;
      temp3[index3].value = setting.value;
      setSettings(temp2);
      setAllSettings(temp3);
    });
  }

  async function deleteSetting(setting) {
    const index = settings.findIndex((s) => s.etag === setting.etag);
    const temp = JSON.parse(JSON.stringify(settings));
    temp[index].loading = true;
    setSettings(temp);
    client.deleteConfigurationSetting(setting).then((response) => {
      const newSettings = _.filter(allSettings, (s) => s.etag !== response.etag);
      setAllSettings(newSettings);
    });
  }

  async function createSetting() {
    const index = allSettings.findIndex((s) => s.key === newSetting.key);
    if (index > -1) {
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: t('setting_with_key_exists'),
          error: true,
        },
      ]);
    } else {
      setLoading(true);
      client.addConfigurationSetting(newSetting).then((response) => {
        setShowCreateModal(false);
        setAllSettings([...allSettings, response]);
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    setLastSearch('');
    getSettings();
  }, []);

  useEffect(() => {
    filterSettings(lastSearch);
  }, [allSettings]);

  useEffect(() => {
    if (showCreateModal) {
      setNewSetting({
        key: '',
        value: ''
      });
    }
  }, [showCreateModal]);

  return (
    <MainPage>
      <Spin size="large" spinning={loading} indicator={<Spinner className="text-accentColor" big />}>
        <div className="flex">
          <H1 text={t('server_config')} />
          <div className="flex ml-auto space-x-2">
            <Button
              key="create"
              size="small"
              type="primary"
              onClick={() => setShowCreateModal(true)}
            >
              {t('create')}
            </Button>
            <Button
              key="reload"
              size="small"
              type="primary"
              onClick={() => getSettings()}
            >
              {t('reload')}
            </Button>
          </div>
        </div>
        <Input
          placeholder={`${t('search')}...`}
          disabled={loading}
          onChange={(obj) => {
            setLastSearch(obj.target.value);
            filterSettings(obj.target.value);
          }}
        />
        <div className="mt-4">{t('showing')}: {settings?.length}</div>
        <Table
          locale={{
            emptyText: t('no_data')
          }}
          bordered
          dataSource={settings}
          pagination={false}
          columns={[
            {
              title: t('key'),
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <span>{row.key}</span>
              ),
            },
            {
              title: t('value'),
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <Input
                  value={row.value}
                  onChange={(obj) => {
                    const index = settings.findIndex((s) => s.etag === row.etag);
                    const temp = JSON.parse(JSON.stringify(settings));
                    temp[index].value = obj.target.value;
                    setSettings(temp);
                  }}
                />
              ),
            },
            {
              title: '',
              align: 'center',
              className: 'text-sm',
              render: (row) => (
                <>
                  <Popconfirm
                    title={t('update_setting_prompt')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() => {
                      updateSetting(row);
                    }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      className="m-1"
                      loading={row.loading}
                    >
                      {t('update')}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={t('delete_setting_prompt')}
                    okText={t('yes')}
                    cancelText={t('no')}
                    onConfirm={() => {
                      deleteSetting(row);
                    }}
                  >
                    <Button
                      type="primary"
                      danger
                      size="small"
                      className="m-1"
                      loading={row.loading}
                    >
                      {t('remove')}
                    </Button>
                  </Popconfirm>
                </>
              ),
            },
          ]}
        />
        <Modal
          title={t('create_setting')}
          open={showCreateModal}
          onCancel={() => setShowCreateModal(false)}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              loading={loading}
              onClick={() => setShowCreateModal(false)}
            >
              {t('close')}
            </Button>,
            <Button
              key="ok"
              type="primary"
              disabled={!newSetting.key ||
                  !newSetting.value ||
                  !(newSetting.key?.length > 0) ||
                  !(newSetting.value?.length > 0)}
              loading={loading}
              onClick={() => createSetting()}
            >
              {t('create')}
            </Button>
          ]}
        >
          <div className="mb-2">
            <strong>{t('key')}</strong>
            <Input
              disabled={loading}
              value={newSetting?.key}
              onChange={(obj) => {
                setNewSetting({
                  ...newSetting,
                  key: obj.target.value.trim()
                });
              }}
            />
          </div>
          <div className="mb-2">
            <strong>{t('value')}</strong>
            <Input
              disabled={loading}
              value={newSetting?.value}
              onChange={(obj) => {
                setNewSetting({
                  ...newSetting,
                  value: obj.target.value.trim()
                });
              }}
            />
          </div>
        </Modal>
      </Spin>
    </MainPage>
  );
}

export default ServerConfig;
