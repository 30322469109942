import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import { H2, H4 } from '../../shared-components/typography/Title';
import backdrop from '../../../assets/images/misc/login_backdrop.png';
import Button from '../../shared-components/buttons/Button';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Checkbox from '../../shared-components/form/Checkbox';
import Select from '../../shared-components/form/Select';
import Logo from '../../shared-components/logo/Logo';
import envConfig from '../../../envConfig';
import api from '../../../api/api';
import { encryptString } from '../../../functions';

function Register() {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const user = useRecoilValue(userAtom);
    const [loading, setLoading] = useState(false);

    if (user) {
      window.location = '/home';
    }

    function submit(values) {
      setLoading(true);
      const payload = {
        FirstName: values.fname,
        LastName: values.lname,
        Email: values.email,
        Password: encryptString(values.password),
        PreferredLanguage: i18n.language === 'es' ? 1 : 0,
      };
      api.post('auth/register', payload)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: t('account_created_successfully'),
              error: false,
            },
          ]);
          history.push('/');
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
    }

    const formik = useFormik({
      initialValues: {
        fname: '',
        lname: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        acceptTos: false
      },
      validationSchema: Yup.object().shape({
        fname: Yup.string().required(t('required_field')),
        lname: Yup.string().required(t('required_field')),
        email: Yup.string()
          .email(t('valid_email_validation'))
          .required(t('required_field')),
        password: Yup.string().required(t('required_field')),
        passwordConfirmation: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            t('same_password')
          )
          .required(t('required_field'))
      }),
      onSubmit: (values, { setSubmitting }) => submit(values),
    });

    return (
      <div className="grid mx-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-6">
        <div className="flex col-span-1 lg:col-span-4 flex-col w-full mt-12 md:mt-48 justify-center items-center">
          <img className="hidden md:block bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
        <div className="flex md:col-span-1 lg:col-span-2 flex-col w-full md:mt-48 justify-center items-center">
          <Logo
            className="w-64 hover:scale-110"
          />
          <H2 className="my-4 text-center" text={t('enter_your_information_to_create_a_new_account')} />
          <Form className="grid gap-2 grid-cols-1 md:grid-cols-2 mt-4 w-10/12 mb-8">
            <Input
              className="col-span-2"
              label={t('email')}
              required
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.errors.email && formik.touched.email && formik.errors.email
              }
            />
            <Input
              className="col-span-1"
              label={t('name')}
              required
              type="text"
              name="fname"
              id="fname"
              autoComplete="given-name"
              autoCapitalize="true"
              value={formik.values.fname}
              onChange={formik.handleChange}
              error={
                formik.errors.fname && formik.touched.fname && formik.errors.fname
              }
            />
            <Input
              className="col-span-1"
              label={t('last_name')}
              required
              type="text"
              name="lname"
              id="lname"
              autoComplete="family-name"
              autoCapitalize="true"
              value={formik.values.lname}
              onChange={formik.handleChange}
              error={
                formik.errors.lname && formik.touched.lname && formik.errors.lname
              }
            />
            <Input
              className="col-span-1"
              label={t('password')}
              required
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.errors.password &&
                formik.touched.password &&
                formik.errors.password
              }
            />
            <Input
              className="col-span-1"
              label={t('confirm_password')}
              required
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              autoComplete="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={
                formik.errors.passwordConfirmation &&
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
            <Checkbox
              label={(
                <>
                  {t('check_to_accept_tos')}
                </>
              )}
              parentClassName="mt-2 col-span-2"
              id="acceptTos"
              name="acceptTos"
              value={formik.values.acceptTos}
              onChange={(obj) => {
                formik.setFieldValue('acceptTos', obj.target.checked);
              }}
              error={
                formik.errors.acceptTos &&
                formik.touched.acceptTos &&
                formik.errors.acceptTos
            }
            />
            <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="ml-6 -mt-2 text-accentColor hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">{t('view_here')}</a>
          </Form>
          <Button
            className="bg-primaryColor mb-6 w-9/12"
            textColor="text-primaryTextColor"
            loaderColor="bg-primaryTextColor"
            borderColor="border-primaryBorderColor"
            loading={loading}
            disabled={!formik.values.acceptTos}
            text={t('submit')}
            onClick={formik.submitForm}
          />
          <H4 className="mb-4" text={t('or')} />
          <Button
            disabled={loading}
            className="bg-secondaryColor mb-6 w-9/12"
            textColor="text-secondaryTextColor"
            borderColor="border-secondaryBorderColor"
            text={t('sign_in')}
            onClick={() => history.push('/')}
          />
        </div>
        <div className="flex md:hidden col-span-1 flex-col w-full mb-4 justify-center items-center">
          <img className="bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
      </div>
    );
}

export default Register;
