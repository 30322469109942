import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { userAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1 } from '../../shared-components/typography/Title';

function SafetyForm() {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation();

  return (
    <MainPage>
      <H1 text={t('safety_form')} />
    </MainPage>
  );
}

export default SafetyForm;
