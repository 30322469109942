import React from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  faCog,
  faUniversity,
  faUser,
  faUsers,
  faHome,
  faSignOut,
  faFileAlt,
  faUpload,
  faHistory,
  faBus,
  faReceipt,
  faFile,
  faFolderOpen
} from '@fortawesome/pro-duotone-svg-icons';
import { userAtom, userPrivilagesAtom, userOperatorsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1 } from '../../shared-components/typography/Title';
import HomeCard from './shared-components/HomeCard';
import envConfig from '../../../envConfig';

function Home() {
  const user = useRecoilValue(userAtom);
  const { t } = useTranslation();
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const userOperators = useRecoilValue(userOperatorsAtom);

  return (
    <MainPage>
      <H1 className="mb-4" text={t('home_intro')} />
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {userPrivilages?.hasGlobalPrivilages && (
          <HomeCard
            icon={faCog}
            title={t('server_config')}
            description={t('server_config_desc')}
            route="/serverconfig"
          />
        )}
        {userPrivilages?.hasAdminPrivilages && (
          <>
            <HomeCard
              icon={faUniversity}
              title={t('operators')}
              description={t('operators_desc')}
              route="/operators"
            />
            <HomeCard
              icon={faFileAlt}
              title={t('storage_file_processes')}
              description={t('storage_file_processes_desc')}
              route="/fileprocesses"
            />
          </>
        )}
        {userPrivilages?.hasCustomerSupportPrivilages && (
          <HomeCard
            icon={faUsers}
            title={t('users')}
            description={t('users_desc')}
            route="/users"
          />
        )}
        {(userPrivilages?.hasGlobalPrivilages ||
          _.filter(userOperators, (op) => op.id === envConfig.TransdevOperatorId).length > 0) && (
          <>
            <HomeCard
              icon={faReceipt}
              title={`Transdev - ${t('invoices')}`}
              description={t('invoices_desc')}
              route="/transdev/invoice"
            />
            <HomeCard
              icon={faFile}
              title={`Transdev - ${t('farebox_collection_submissions')}`}
              description={t('farebox_collection_submissions_desc')}
              route="/transdev/farebox"
            />
            <HomeCard
              icon={faFolderOpen}
              title={`Transdev - ${t('incidentals')}`}
              description={t('incidentals_submissions_desc')}
              route="/transdev/incidentals"
            />
          </>
        )}
        {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasOperatorAdminPrivilages &&
        _.filter(userOperators, (op) => op.id === envConfig.AmaOperatorId).length > 0)) && (
          <HomeCard
            icon={faBus}
            title={`AMA - ${t('holidays')}`}
            description={t('holidays_desc')}
            route="/ama/holidays"
          />
        )}
        {(userPrivilages?.hasGlobalPrivilages || (userPrivilages?.hasRouteValidationPrivilages &&
        _.filter(userOperators, (op) => op.id === envConfig.AmaOperatorId).length > 0)) && (
          <HomeCard
            icon={faBus}
            title={`AMA - ${t('route_validation')}`}
            description={t('route_validation_desc')}
            route="/ama/routevalidation"
          />  
        )}
        <HomeCard
          icon={faUpload}
          title={t('document_upload')}
          description={t('document_upload_desc')}
          route="/document-upload"
        />
        <HomeCard
          icon={faHistory}
          title={t('upload_history')}
          description={t('upload_history_desc')}
          route="/upload-history"
        />
        
      </div>
    </MainPage>
  );
}

export default Home;
