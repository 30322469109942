import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Table,
  Tag,
  Modal,
  Button,
  Popconfirm
} from 'antd';
import { userAtom, notificationsAtom, userPrivilagesAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import {
  storageFileProcessesFilters,
} from '../../../utils';
import {
  storageFileProcessesStatusToString,
  thousand
} from '../../../functions';
import Select from '../../shared-components/form/Select';
import CustomButton from '../../shared-components/buttons/Button';

function FileProcesses() {
  const user = useRecoilValue(userAtom);
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [filesFilter, setFilesFilter] = useState(1);
  const [selectedFileForLogs, setSelectedFileForLogs] = useState(null);

  function getFiles(selectedPage) {
    setCurrentPage(selectedPage);
    setLoading(true);
    api.get(`functions/storagefiles/list?filter=${filesFilter}&page=${selectedPage}&size=${pageSize}`)
    .then((response) => {
      setLoading(false);
      if (response.data.success) {
        setFiles(response.data.data.files);
        setTotalFiles(response.data.data.total);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoading(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getStatusColor(status) {
    if (status === 1 || status === 2) {
        return 'green';
      }
    if (status === 3) {
      return 'red';
    }
    return '';
  }

  function forceFile(fileId) {
    const tempFileId = fileId;
    const tempFiles = [...files];
    const index = tempFiles.findIndex((r) => r.id === fileId);
    tempFiles[index].loading = true;
    setFiles(tempFiles);
    api.get(`functions/storagefiles/force?storageFileId=${fileId}`)
    .then((response) => {
      if (response.data.success) {
        setNotifications([
          ...notifications,
          {
            title: t('success'),
            description: response.data.message,
            error: false,
          }
        ]);
        getFiles(currentPage);
      } else {
        const tempFiles2 = [...files];
        const index2 = tempFiles2.findIndex((r) => r.id === fileId);
        tempFiles2[index2].loading = false;
        setFiles(tempFiles2);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      const tempFiles2 = [...files];
      const index2 = tempFiles2.findIndex((r) => r.id === fileId);
      tempFiles2[index2].loading = false;
      setFiles(tempFiles2);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  useEffect(() => {
    setFilesFilter(1);
  }, []);

  useEffect(() => {
    getFiles(0);
  }, [pageSize, filesFilter]);

  return (
    <MainPage>
      <H1 text={t('storage_file_processes')} />
      <div>
        <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-2">
          <Select
            className="col-span-1 md:col-span-2 lg:col-start-3 lg:col-span-1 xl:col-start-4 mt-2"
            disabled={loading}
            label={t('filter')}
            id="filter"
            name="filter"
            value={filesFilter}
            onChange={(obj) => {
              setFilesFilter(parseInt(obj.target.value, 10));
            }}
            options={_.map(storageFileProcessesFilters, (f) => ({
            key: f.id,
            value: f.id,
            text: t(f.name),
            }))}
          />
          <CustomButton
            className="bg-primaryColor col-span-1 md:col-span-2 lg:col-span-1 mt-2"
            textColor="text-primaryTextColor"
            loaderColor="bg-primaryTextColor"
            borderColor="border-primaryBorderColor"
            loading={loading}
            text={t('refresh')}
            onClick={() => getFiles(currentPage)}
          />
        </div>
        <div className="mt-2">
          <p>{t('total_files')}: {thousand(totalFiles)}</p>
          <Table
            bordered
            locale={{
              emptyText: t('no_data')
            }}
            rowKey="id"
            loading={loading}
            className="mt-2"
            pagination={{
              pageSize,
              showSizeChanger: true,
              current: currentPage + 1,
              total: totalFiles,
              onChange: (page, size) => {
                if (page - 1 !== currentPage) {
                  setCurrentPage(page - 1);
                  getFiles(page - 1);
                } else {
                  setPageSize(size);
                }
              }
            }}
            dataSource={files}
            columns={[
              {
                title: t('created_on'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{dayjs(row.createdOn).format('MMM DD, YYYY hh:mm A')}</span>
                ),
              },
              {
                title: t('file'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.container}/{row.fileName}</span>
                ),
              },
              {
                title: t('status'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <Tag color={getStatusColor(row.status)}>
                    {t(storageFileProcessesStatusToString(row.status))}
                  </Tag>
                ),
              },
              {
                title: t('message'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <span>{row.message}</span>
                ),
              },
              {
                title: t('actions'),
                align: 'center',
                className: 'text-sm',
                render: (row, object, index) => (
                  <>
                    {row.status === 0 && (
                      <CustomButton
                        className="bg-primaryColor px-2"
                        textColor="text-primaryTextColor"
                        loaderColor="bg-primaryTextColor"
                        disabled={row.status !== 0 || row.loading}
                        borderColor="border-primaryBorderColor"
                        text={t('force')}
                        loading={row.loading}
                        onClick={() => forceFile(row.id)}
                      />
                    )}
                    {(row.status === 2 || row.status === 3) && (
                      <CustomButton
                        className="bg-primaryColor px-2"
                        textColor="text-primaryTextColor"
                        loaderColor="bg-primaryTextColor"
                        borderColor="border-primaryBorderColor"
                        disabled={row.logs.length === 0}
                        text={t('logs')}
                        onClick={() => setSelectedFileForLogs(row)}
                      />
                    )}
                  </>
                )
              }
            ]}
          />
        </div>
      </div>
      <Modal
        title={`${t('logs')}: ${selectedFileForLogs?.container}/${selectedFileForLogs?.fileName}`}
        open={selectedFileForLogs}
        onCancel={() => setSelectedFileForLogs(null)}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="closelogsbutton"
              type="primary"
              danger
              className="bg-rose-500 px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              onClick={() => setSelectedFileForLogs(null)}
            >
              {t('close')}
            </Button>
          </div>
        ]}
      >
        <Table
          bordered
          locale={{
            emptyText: t('no_data')
          }}
          rowKey="id"
          loading={loading}
          className="mt-2"
          dataSource={_.orderBy(selectedFileForLogs?.logs, (l) => l.createdAt, 'desc')}
          columns={[
            {
              title: t('created_on'),
              align: 'center',
              className: 'text-sm',
              render: (row, object, index) => (
                <span>{dayjs(row.createdAt).format('MMM DD, YYYY hh:mm A')}</span>
              ),
            },
            {
              title: t('message'),
              align: 'center',
              className: 'text-sm',
              render: (row, object, index) => (
                <span>{row.message}</span>
              ),
            },
          ]}
        />
      </Modal>
    </MainPage>
  );
}

export default FileProcesses;
