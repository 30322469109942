import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Table,
  Tag,
  Popover,
  Modal,
  Switch
} from 'antd';
import Link from 'antd/es/typography/Link';
import { userAtom, notificationsAtom, userPrivilagesAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H4 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import { Spinner } from '../../shared-components/Spinner';
import Select from '../../shared-components/form/Select';
import Button from '../../shared-components/buttons/Button';
import PopconfirmButton from '../../shared-components/buttons/PopconfirmButton';
import Form from '../../shared-components/form/Form';
import CustomInput from '../../shared-components/form/Input';

function AmaRouteValidation() {
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const userPrivilages = useRecoilValue(userPrivilagesAtom);

  const [loadingStations, setLoadingStations] = useState(false);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);

  const [loadingRoutes, setLoadingRoutes] = useState(false);
  const [loadingRoutesForValidation, setLoadingRoutesForValidation] = useState(false);
  const [stationRoutes, setStationRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [filteredRouteValidation, setFilteredRouteValidation] = useState([]);
  const [routeValidation, setRouteValidation] = useState([]);
  const [selectedRouteValidation, setSelectedRouteValidation] = useState(null);
  const [comments, setComments] = useState('');
  const [submittingUpdate, setSubmittingUpdate] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [cancellingMissedTrip, setCancellingMissedTrip] = useState(false);
  const [showOnlyMissed, setShowOnlyMissed] = useState(false);
  const [counts, setCounts] = useState({ total: 0, missed: 0 })

  function setRouteValidationFiltered(routes) {
    setRouteValidation(routes);
    if (showOnlyMissed) {
      setFilteredRouteValidation(_.filter(routes, (s) => s.cancellationId));
    } else {
      setFilteredRouteValidation(routes);
    }
  }

  function getStations() {
    setLoadingStations(true);
    api.get('ama/stations')
    .then((response) => {
      if (response.data.success) {
        setLoadingStations(false);
        setStations(response.data.data);
        setSelectedStation(response.data.data[0]);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingStations(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getRoutes() {
    setLoadingRoutes(true);
    api.get(`ama/routes?stationId=${selectedStation}`)
    .then((response) => {
      if (response.data.success) {
        setLoadingRoutes(false);
        setStationRoutes(response.data.data);
        setSelectedRoute(response.data.data[0]);
        // setStationRoutes(['', ...response.data.data]);
        // setSelectedRoute('');
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
        setLoadingRoutes(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getRoutesForValidation() {
    setLoadingRoutesForValidation(true);
    const date = dayjs(selectedDate);
    const utcOffset = date.utcOffset();
    const dateString = date.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    api.get(`ama/route/validation?stationId=${selectedStation}&routeId=${selectedRoute}&filterDate=${dateString}`)
    .then((response) => {
      if (response.data.success) {
        setLoadingRoutesForValidation(false);
        setRouteValidationFiltered(response.data.data.routes);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
        setLoadingRoutesForValidation(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function updateMissed(itineraryId) {
    const tempRoutes = [...routeValidation];
    const index = tempRoutes.findIndex((r) => r.id === itineraryId);
    tempRoutes[index].loading = true;
    setSubmittingUpdate(true);
    setRouteValidationFiltered(tempRoutes);
    const date = dayjs(selectedDate);
    const utcOffset = date.utcOffset();
    const dateString = date.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    api.post('ama/route/validation', {
      StationId: selectedStation,
      ItineraryId: itineraryId,
      Comment: comments,
      Date: dateString
    })
    .then((response) => {
      setSubmittingUpdate(false);
      if (response.data.success) {
        setRouteValidationFiltered(response.data.data.routes);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingRoutes(false);
      setSubmittingUpdate(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function updateMissedWithComments() {
    updateMissed(selectedRouteValidation?.id);
  }

  function cancelMissedTrip(itineraryId) {
    setCancellingMissedTrip(true);
    const date = dayjs(selectedDate);
    const utcOffset = date.utcOffset();
    const dateString = date.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    api.post('ama/route/validation/cancel', {
      StationId: selectedStation,
      ItineraryId: itineraryId,
      Date: dateString
    })
    .then((response) => {
      setCancellingMissedTrip(false);
      if (response.data.success) {
        setRouteValidationFiltered(response.data.data.routes);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setCancellingMissedTrip(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  useEffect(() => {
    getStations();
    setSelectedDate(dayjs().format('MMM DD, YYYY'));
  }, []);

  useEffect(() => {
    if (selectedStation) {
      setSelectedRoute(null);
      setRouteValidationFiltered([]);
      setStationRoutes([]);
      getRoutes();
    }
  }, [selectedStation]);

  useEffect(() => {
    if (selectedRoute !== null && selectedRoute !== undefined) {
      getRoutesForValidation();
    }
  }, [selectedRoute, selectedDate]);

  useEffect(() => {
    setComments('');
  }, [selectedRouteValidation]);

  useEffect(() => {
    setRouteValidationFiltered(routeValidation);
  }, [showOnlyMissed])

  useEffect(() => {
    setCounts({
      total: filteredRouteValidation.length,
      missed: _.filter(filteredRouteValidation, (s) => s.cancellationId).length
    });
  }, [filteredRouteValidation]);

  return (
    <MainPage>
      <H1 text={`AMA - ${t('route_validation')}`} />
      {loadingStations && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!loadingStations && (
        <div>
          <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {userPrivilages.hasOperatorAdminPrivilages && (
              <div className="col-span-1 md:ml-2 space-x-2 mt-4">
                <strong>{t('show_only_missed')}</strong>
                <Switch
                  className="-mt-1"
                  checked={showOnlyMissed}
                  onChange={(checked) => setShowOnlyMissed(checked)}
                />
              </div>
            )}
            <Select
              className="col-span-1 mt-2 md:ml-2"
              disabled={loadingStations}
              label={t('station')}
              id="station"
              name="station"
              value={selectedStation}
              onChange={(obj) => {
                setSelectedStation(obj.target.value);
              }}
              options={_.map(stations, (s) => ({
                key: s,
                value: s,
                text: s,
              }))}
            />
            <Select
              className="col-span-1 mt-2 md:ml-2"
              disabled={loadingRoutes}
              label={t('route')}
              id="route"
              name="route"
              value={selectedRoute}
              onChange={(obj) => {
                setSelectedRoute(obj.target.value);
              }}
              options={_.map(stationRoutes, (s) => ({
                key: s.length > 0 ? s : 'any',
                value: s.length > 0 ? s : '',
                text: s.length > 0 ? s : t('any'),
              }))}
            />
            <div className="col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 lg:ml-2 md:mr-2">
              <DatePicker
                disabled={loadingRoutesForValidation || !userPrivilages.hasOperatorAdminPrivilages}
                className="w-full h-11 mt-2 lg:ml-0 xl:ml-2 bg-transparent"
                value={dayjs(selectedDate)}
                maxDate={dayjs()}
                onChange={(obj) => {
                  if (obj <= dayjs()) {
                    setSelectedDate(obj.format('MMM DD, YYYY'));
                  } else {
                    setSelectedDate(dayjs().format('MMM DD, YYYY'));
                  }
                }}
                format="MMM DD, YYYY"
              />
            </div>
            <Button
              className="bg-primaryColor col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 mt-2 lg:ml-2"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              loading={loadingRoutesForValidation}
              disabled={selectedRoute === null || selectedRoute === undefined}
              text={t('refresh')}
              onClick={() => getRoutesForValidation()}
            />
          </div>
          {loadingRoutesForValidation && (
            <div className="w-full h-full flex items-center">
              <Spinner className="mx-auto mt-52 text-accentColor" big />
            </div>
          )}
          {!loadingRoutesForValidation && routeValidation?.length > 0 && (
            <div className="mt-2">
              <strong className="float-right mb-2 mr-2">
                {t('total')}: {counts.total} - {t('missed')}: {counts.missed}
              </strong>
              <Table
                bordered
                locale={{
                  emptyText: t('no_data')
                }}
                pagination={false}
                rowKey="id"
                className="mt-2"
                loading={cancellingMissedTrip}
                dataSource={filteredRouteValidation}
                columns={[
                  {
                    title: t('route'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.routeNumber}</span>
                    ),
                  },
                  {
                    title: t('train'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.route}</span>
                    ),
                  },
                  // {
                  //   title: t('vehicle'),
                  //   align: 'center',
                  //   className: 'text-sm',
                  //   render: (row, object, index) => (
                  //     <span>{row.vehicle}</span>
                  //   ),
                  // },
                  {
                    title: t('scheduled_arrival_time'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.scheduledArrivalTime ? row.scheduledArrivalTimeString : 'GAR'}</span>
                    ),
                  },
                  {
                    title: t('scheduled_departure_time'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.scheduledDepartureTime ? row.scheduledDepartureTimeString : 'GAR' }</span>
                    ),
                  },
                  {
                    title: t('itinerary_type'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.itineraryType}</span>
                    ),
                  },
                  {
                    title: t('missed_trip'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <>
                        {row.cancellationId && (
                          <Popover
                            content={
                              <div>
                                {row.comment && (
                                  <>
                                    <span>{row.comment}</span>
                                    <br />
                                    <br />
                                  </>
                                )}
                                <span>
                                  {t('cancelled_on')}: {dayjs(row.cancellationDate).format('MMM DD, YYYY')} {dayjs(row.cancellationDate).format('hh:mm a')}
                                </span>
                                {userPrivilages.hasOperatorAdminPrivilages && (
                                  <>
                                    <br />
                                    <PopconfirmButton
                                      className="bg-rose-500 hover:bg-rose-600 mt-2 w-full"
                                      textColor="text-white"
                                      loaderColor="bg-primaryTextColor"
                                      loading={cancellingMissedTrip}
                                      title={t('revert_missed_trip')}
                                      description={t('do_you_want_to_revert_missed_trip')}
                                      okText={t('yes')}
                                      cancelText={t('no')}
                                      text={t('revert_missed_trip')}
                                      onConfirm={() => cancelMissedTrip(row.id)}
                                    />
                                  </>
                                )}
                              </div>
                            }
                          >
                            <Tag color="red">
                              {t('marked_by')} {row.cancelledBy}
                            </Tag>
                          </Popover>
                        )}
                        {!row.cancellationId && (
                          <PopconfirmButton
                            title={t('leave_comment_prompt')}
                            description={t('do_you_want_to_leave_comment_for_trip')}
                            okText={t('yes')}
                            onCancel={() => updateMissed(row.id)}
                            onConfirm={() => {
                              setSelectedRouteValidation(row)
                            }}
                            cancelText={t('no')}
                            className="bg-primaryColor w-full"
                            textColor="text-primaryTextColor"
                            loaderColor="bg-primaryTextColor"
                            borderColor="border-primaryBorderColor"
                            loading={row.loading}
                            text={t('mark_missed')}
                          />
                        )}
                      </>
                    ),
                  }
                ]}
              />
            </div>
          )}
        </div>
      )}
      <Modal
        title={t('comments_for_trip')}
        open={selectedRouteValidation}
        onCancel={() => {
          setSelectedRouteValidation(null);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="closecommentsbutton"
              type="primary"
              danger
              className="bg-rose-500 px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              disabled={submittingUpdate}
              onClick={() => setSelectedRouteValidation(null)}
              text={t('cancel')}
            />
            <Button
              className="bg-primaryColor px-4"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              loading={submittingUpdate}
              text={t('submit')}
              onClick={() => {
                const promise = new Promise(function (resolve, reject) {
                  updateMissedWithComments();
                  resolve();
                });
                promise.then(() => {
                  setSelectedRouteValidation(null);
                });
              }}
            />
          </div>
        ]}
      >
        <div>
          <Form className="mt-4 mx-auto w-full mb-8">
            <CustomInput
              className="col-span-2 mb-2"
              label={t('comments')}
              type="text"
              name="text"
              id="text"
              disabled={submittingUpdate}
              value={comments}
              onChange={(obj) => setComments(obj.target.value)}
            />
          </Form>
        </div>
      </Modal>
    </MainPage>
  );
}

export default AmaRouteValidation;
