import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  Table,
  Select,
  Input,
  Button,
  Switch,
  Tag,
  Popconfirm,
  Avatar,
  Spin,
  Modal,
  Divider
} from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { notificationsAtom, userPrivilagesAtom, tokenAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { Spinner } from '../../shared-components/Spinner';
import { H1, H4 } from '../../shared-components/typography/Title';
import Form from '../../shared-components/form/Form';
import CustomInput from '../../shared-components/form/Input';
import api from '../../../api/api';
import { thousand } from '../../../functions';
import envConfig from '../../../envConfig';

function Users() {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const sortFilters = [
    { name: `${t('created_at')} ASC`, type: 0 },
    { name: `${t('created_at')} DESC`, type: 1 },
    { name: `${t('name')} Z-A`, type: 2 },
    { name: `${t('created_at')} Z-A`, type: 3 },
    { name: `${t('email')} Z-A`, type: 4 },
    { name: `${t('email')} Z-A`, type: 5 },
  ];
  const searchTypes = [
    { name: t('all'), type: 0 },
    { name: t('pending'), type: 1 },
    { name: t('confirmed'), type: 2 }
  ];
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const userPrivilages = useRecoilValue(userPrivilagesAtom);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [loadingUserOperators, setLoadingOperators] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [lastSearchTerm, setLastSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchResult, setSearchResult] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userWasEdited, setUserWasEdited] = useState(false);
  const [userOperators, setUserOperators] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedSortFilter, setSelectedSortFilter] = useState(0);
  const [selectedSearchType, setSelectedSearchType] = useState(0);

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [inviteUserData, setInviteUserData] = useState(null);
  const [invitingUser, setInvitingUser] = useState(false);

  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [forcePassword, setForcePassword] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);

  const [loadingImpersonate, setLoadingImpersonate] = useState(false);
  const setToken = useSetRecoilState(tokenAtom);

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required(t('required_field')),
      lname: Yup.string().required(t('required_field')),
      email: Yup.string()
        .email(t('valid_email_validation'))
        .required(t('required_field'))
    }),
    onSubmit: (values, { setSubmitting }) => {
      setInvitingUser(true);
    api
      .post(
        'admin/users/invite', {
          FirstName: values.fname,
          LastName: values.lname,
          Email: values.email
        }
      )
      .then((response) => {
        setInvitingUser(false);
        if (response.data.success) {
          setInviteUserData(null);
          setShowInviteUserModal(false);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setInvitingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
    },
  });

  function impersonateUser() {
    setLoadingImpersonate(true);
    api
      .post(
        'auth/impersonate',
        {
          UserId: selectedUser?.id
        }
      )
      .then((response) => {
        setLoadingImpersonate(false);
        if (response.data.success) {
          localStorage.setItem(
            `${envConfig.StoragePrefix}-user`,
            JSON.stringify(response.data.data.profile)
          );
          localStorage.setItem(
            `${envConfig.StoragePrefix}-token`,
            response.data.data.accessToken
          );
          localStorage.setItem(
            `${envConfig.StoragePrefix}-token-date`,
            dayjs().toString()
          );
          localStorage.setItem(`${envConfig.StoragePrefix}-impersonate`, true);
          setToken(response.data.data.accessToken);
          window.location = '/home';
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoadingImpersonate(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function getOperators() {
    api.get('operators/all')
      .then((response) => {
        if (response.data.success) {
          setOperators(response.data.data);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      })
  }

  function toggleBlock() {
    setUpdatingUser(true);
    api
      .post(
        'admin/users/block/update',
        {
          UserId: selectedUser?.id,
          IsBlocked: !selectedUser?.isBlocked
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          setSelectedUser({
            ...selectedUser,
            isBlocked: !selectedUser?.isBlocked
          });
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function approveUser() {
    setUpdatingUser(true);
    api
      .post(
        'admin/users/approve',
        {
          UserId: selectedUser?.id
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          setSelectedUser({
            ...selectedUser,
            approvedOn: dayjs().toString()
          });
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function addRole(role) {
    setUpdatingUser(true);
    const tempRoles = [...userRoles];
    const roleKey = role.key;
    api
      .post(
        'admin/users/roles/assign',
        {
          UserId: selectedUser?.id,
          Role: role.index
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          for (let i = 0; i < tempRoles.length; i++) {
            if (tempRoles[i].key === roleKey) {
              tempRoles[i].active = true;
            }
          }
          setUserRoles(tempRoles);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('role_updated_successfully'),
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function removeRole(role) {
    setUpdatingUser(true);
    const roleKey = role.key;
    const tempRoles = [...userRoles];
    api
      .post(
        'admin/users/roles/remove',
        {
          UserId: selectedUser?.id,
          Role: role.index
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          for (let i = 0; i < tempRoles.length; i++) {
            if (tempRoles[i].key === roleKey) {
              tempRoles[i].active = false;
            }
          }
          setUserRoles(tempRoles);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('sucess'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('role_updated_successfully'),
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function filterOperators(userOps) {
    const tempOps = [...operators];
    for (let i = 0; i < tempOps.length; i++) {
      tempOps[i].active = false;
      for (let j = 0; j < userOps.length; j++) {
        if (tempOps[i].id === userOps[j].id) {
          tempOps[i].active = true;
        }
      }
    }
    setUserOperators(tempOps);
  }

  function assignOperator(operatorId) {
    setUpdatingUser(true);
    api
      .post(
        'operators/users/assign',
        {
          UserId: selectedUser?.id,
          OperatorId: operatorId
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          filterOperators(response.data.data);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('operater_assigned_successfully'),
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function removeOperator(operatorId) {
    setUpdatingUser(true);
    api
      .post(
        'operators/users/remove',
        {
          UserId: selectedUser?.id,
          OperatorId: operatorId
        }
      )
      .then((response) => {
        setUpdatingUser(false);
        if (response.data.success) {
          setUserWasEdited(true);
          filterOperators(response.data.data);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('operater_removed_successfully'),
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setUpdatingUser(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function getUserOperators() {
    setLoadingOperators(true);
    api.get(`operators/user/${selectedUser?.id}`)
      .then((response) => {
        setLoadingOperators(false);
        if (response.data.success) {
          filterOperators(response.data.data);
        } else {
          setUserOperators([]);
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            }
          ]);
        }
      })
      .catch((error) => {
        setLoadingOperators(false);
        setUserOperators([]);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          }
        ]);
      })
  }

  function forceResetUserPassword() {
    setResettingPassword(true);
    api
      .post(
        'admin/users/password/reset',
        {
          UserId: selectedUser?.id,
          TempPassword: temporaryPassword
        }
      )
      .then((response) => {
        setResettingPassword(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('password_reset_successfully'),
                error: false,
              },
            ]);
          }
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setResettingPassword(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function search(value, page) {
    setLastSearchTerm(value);
    setCurrentPage(page);
    setLoading(true);
    api
      .post(
        'admin/users/search',
        {
          Query: value,
          Size: pageSize,
          Page: page
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSearchResult(response.data.data);
        } else {
          setSearchResult({
            ...searchResult,
            users: []
          });
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
  }

  function onSearch(value) {
    if (value === '' || value === null) {
      setSearchResult({
        ...searchResult,
        users: []
      });
      return;
    }
    setHasSearched(true);
    search(value, 0);
  }

  const onTableChange = (page, size) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      search(lastSearchTerm, page - 1);
    } else {
      setPageSize(size);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setTemporaryPassword('');
      getUserOperators();
      const tempRoles = [...envConfig.Roles];
      for (let i = 0; i < tempRoles.length; i++) {
        tempRoles[i].active = false;
        for (let j = 0; j < selectedUser.roles.length; j++) {
          if (tempRoles[i].key === selectedUser.roles[j]) {
            tempRoles[i].active = true;
          }
        }
      }
      setUserRoles(tempRoles);
    } else {
      setUserOperators([]);
      setUserRoles([]);
      if (userWasEdited) {
        setUserWasEdited(false);
        search(lastSearchTerm, currentPage);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    setTemporaryPassword('');
  }, [forcePassword]);

  useEffect(() => {
    search(lastSearchTerm, 0);
  }, [selectedSearchType, selectedSortFilter, pageSize]);

  useEffect(() => {
    getOperators();
    search('');
  }, []);

  return (
    <MainPage>
      <div className="flex">
        <H1 text={t('users')} />
        <div className="flex ml-auto space-x-2">
          <Button
            key="create"
            size="small"
            type="primary"
            onClick={() => {
              setInviteUserData(null);
              setShowInviteUserModal(true);
            }}
          >
            {t('invite')}
          </Button>
          <Button
            key="reload"
            size="small"
            type="primary"
            onClick={() => {
              search(lastSearchTerm, 0);
            }}
          >
            {t('reload')}
          </Button>
        </div>
      </div>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <div className="px-4 col-span-1 md:col-span-2 lg:col-span-1 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin indicator={<Spinner className="text-accentColor" />} spinning={loading}>
              <dt className="text-center text-sm font-medium text-gray-500 truncate">{t('total_users')}</dt>
              <dd className="text-center mt-1 text-3xl font-semibold text-gray-900">{thousand(searchResult?.total)}</dd>
            </Spin>
          </div>
          <div className="px-4 col-span-1 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin indicator={<Spinner className="text-accentColor" />} spinning={loading}>
              <dt className="text-center text-sm font-medium text-gray-500 truncate">{t('total_users_pending')}</dt>
              <dd className="text-center mt-1 text-3xl font-semibold text-gray-900">{thousand(searchResult?.totalPendingInSearch)}</dd>
            </Spin>
          </div>
          <div className="px-4 col-span-1 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Spin indicator={<Spinner className="text-accentColor" />} spinning={loading}>
              <dt className="text-center text-sm font-medium text-gray-500 truncate">{t('total_users_confirmed')}</dt>
              <dd className="text-center mt-1 text-3xl font-semibold text-gray-900">{thousand(searchResult?.totalApprovedInSearch)}</dd>
            </Spin>
          </div>
        </dl>
      </div>
      <div className="lg:flex items-center justify-center">
        <Input.Search
          placeholder={`${t('search')}...`}
          allowClear
          className="mt-4"
          enterButton={t('search')}
          size="large"
          onSearch={(val) => onSearch(val)}
        />
        <div className="grid grid-cols-2 md:space-x-2 lg:flex">
          <Select
            className="h-10 col-span-2 mt-2 md:col-span-1 lg:mt-4 lg:w-36 lg:ml-2"
            value={selectedSearchType}
            onChange={(value) => setSelectedSearchType(value)}
          >
            {_.map(searchTypes, (filter) => (
              <Option
                key={filter.type}
                value={filter.type}
                className="text-sm flex items-center"
              >
                {filter.name}
              </Option>
            ))}
          </Select>
          <Select
            className="h-10 col-span-2 mt-2 md:col-span-1 lg:mt-4 lg:w-36"
            value={selectedSortFilter}
            onChange={(value) => setSelectedSortFilter(value)}
          >
            {_.map(sortFilters, (filter) => (
              <Option
                key={filter.type}
                value={filter.type}
                className="text-sm flex items-center"
              >
                {filter.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt-4">{t('showing')}: {hasSearched && searchResult?.users ? searchResult?.users?.length : 0}</div>
      <Table
        locale={{
          emptyText: t('no_data')
        }}
        bordered
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage + 1,
          total: searchResult?.totalInSearch,
          onChange: onTableChange
        }}
        dataSource={searchResult?.users}
        columns={[
          {
            title: t('user'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>
                <Avatar src={row.avatar} className="h12 w-8 mr-2" />
                {row.completeName}
              </span>
            ),
          },
          {
            title: t('email'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{row.email}</span>
            ),
          },
          {
            title: t('blocked'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{!row.isBlocked ? <Tag color="green">{t('no')}</Tag> : <Tag color="red">{t('yes')}</Tag>}</span>
            ),
          },
          {
            title: t('approved_on'),
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <span>{row.acceptedOn ? dayjs(row.acceptedOn).format('MMM DD YYYY hh:mm A') : '-'}</span>
            ),
          },
          {
            title: '',
            align: 'center',
            className: 'text-sm',
            render: (row) => (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setSelectedUser(row);
                }}
              >
                {t('select')}
              </Button>
            ),
          }
        ]}
      />
      <Modal
        title={
          <span>
            <Avatar src={selectedUser?.avatar} className="h12 w-8 mr-2" />
            {selectedUser?.completeName}
          </span>
        }
        open={selectedUser}
        onCancel={() => {
          setSelectedUser(null);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            {userPrivilages.hasGlobalPrivilages && selectedUser?.acceptedOn && (
              <Popconfirm
                key="approvepopup"
                title={t('impersonate_prompt')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => {
                  impersonateUser();
                }}
              >
                <Button
                  key="approvebutton"
                  type="primary"
                  loading={loadingImpersonate}
                >
                  {t('impersonate')}
                </Button>
              </Popconfirm>
            )}
            {!selectedUser?.acceptedOn && (
              <Popconfirm
                key="approvepopup"
                title={t('approve_user_prompt')}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => {
                  approveUser();
                }}
              >
                <Button
                  key="approvebutton"
                  type="primary"
                  loading={updatingUser}
                >
                  {t('approve')}
                </Button>
              </Popconfirm>
            )}
            <Popconfirm
              key="blockpopup"
              title={selectedUser?.isBlocked ? t('unblock_user_prompt') : t('block_user_prompt')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => {
                toggleBlock();
              }}
            >
              <Button
                key="blockbutton"
                type="primary"
                danger
                loading={updatingUser}
              >
                {selectedUser?.isBlocked ? t('unblock') : t('block')}
              </Button>
            </Popconfirm>
            <Button
              key="forceresetpassword"
              type="primary"
              onClick={() => setForcePassword(true)}
              loading={updatingUser}
            >
              {t('reset_password')}
            </Button>
            <Button
              key="closebutton"
              type="primary"
              loading={updatingUser}
              onClick={() => {
                setSelectedUser(null);
              }}
            >
              {t('close')}
            </Button>
          </div>
        ]}
      >
        <div>
          {userPrivilages?.hasAdminPrivilages && (
            <>
              <div className="mt-4 mb-4">
                <H4 text={t('roles')} />
                {_.map(userRoles, (r) => (
                  <div className="mt-2 mb-2">
                    <span>{t(r.name)}</span>
                    <Switch
                      disabled={updatingUser}
                      style={{ float: 'right' }}
                      checked={r.active}
                      onChange={(checked) => {
                        if (r.active) {
                          removeRole(r);
                        } else {
                          addRole(r);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
              <Divider />
            </>
          )}
          <div className="mt-4">
            <H4 text={t('operators')} />
              {_.map(userOperators, (op) => (
                <div className="mt-2 mb-2">
                  <span>{i18n.language === 'en' ? op.nameEn : op.nameEs}</span>
                  <Switch
                    disabled={updatingUser}
                    style={{ float: 'right' }}
                    checked={op.active}
                    onChange={(checked) => {
                      if (op.active) {
                        removeOperator(op.id);
                      } else {
                        assignOperator(op.id);
                      }
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </Modal>
      <Modal
        title={t('invite_new_user')}
        open={showInviteUserModal}
        onCancel={() => {
          setShowInviteUserModal(false);
          setInviteUserData(null);
        }}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="closebutton"
              type="primary"
              danger
              loading={invitingUser}
              onClick={() => {
                setShowInviteUserModal(false);
                setInviteUserData(null);
              }}
            >
              {t('close')}
            </Button>
            <Popconfirm
              key="invitepopup"
              title={t('confirm_data_is_correct')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={formik.submitForm}
            >
              <Button
                key="blockbutton"
                type="primary"
                loading={invitingUser}
              >
                {t('invite')}
              </Button>
            </Popconfirm>
          </div>
        ]}
      >
        <Form className="grid gap-2 grid-cols-1 md:grid-cols-2 mt-4 mx-auto w-10/12 mb-8">
          <CustomInput
            className="col-span-2 mb-2"
            label={t('email')}
            required
            type="email"
            name="email"
            id="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
          <CustomInput
            className="col-span-1 mb-2"
            label={t('name')}
            required
            type="text"
            name="fname"
            id="fname"
            autoComplete="given-name"
            autoCapitalize="true"
            value={formik.values.fname}
            onChange={formik.handleChange}
            error={
              formik.errors.fname && formik.touched.fname && formik.errors.fname
            }
          />
          <CustomInput
            className="col-span-1 mb-2"
            label={t('last_name')}
            required
            type="text"
            name="lname"
            id="lname"
            autoComplete="family-name"
            autoCapitalize="true"
            value={formik.values.lname}
            onChange={formik.handleChange}
            error={
              formik.errors.lname && formik.touched.lname && formik.errors.lname
            }
          />
        </Form>
      </Modal>
      <Modal
        title={t('reset_password')}
        open={forcePassword}
        onCancel={() => setForcePassword(false)}
        footer={[
          <div className="flex space-x-2">
            <div className="mr-auto" />
            <Button
              key="closebuttonreset"
              type="primary"
              danger
              loading={resettingPassword}
              onClick={() => setForcePassword(false)}
            >
              {t('close')}
            </Button>
            <Popconfirm
              key="confirmreset"
              title={t('are_you_sure_reset_password')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => forceResetUserPassword()}
            >
              <Button
                key="confirmresetbutton"
                type="primary"
                loading={resettingPassword}
              >
                {t('submit')}
              </Button>
            </Popconfirm>
          </div>
        ]}
      >
        <Form className="grid gap-2 grid-cols-1 mt-4 mx-auto w-10/12 mb-8">
          <CustomInput
            className="col-span-1 mb-2"
            label={`${t('new_password')} (${t('optional')})`}
            required
            name="temppass"
            id="temppass"
            value={temporaryPassword}
            onChange={(obj) => setTemporaryPassword(obj.target.value)}
          />
        </Form>
      </Modal>
    </MainPage>
  );
}

export default Users;
