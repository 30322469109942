import axios from 'axios';
import { useHistory } from 'react-router-dom';
import envConfig from '../envConfig';

function newAbortSignal(timeout) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout || 0);
  return abortController.signal;
}

const api = axios.create({
  baseURL: envConfig.ApiUrl,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.ApiKey,
    Authorization: `Bearer ${window.localStorage.getItem(`${envConfig.StoragePrefix}-token`)}`
  },
  timeout: 2700000,
  signal: newAbortSignal(2700000)
});

api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem(`${envConfig.StoragePrefix}-lang`)}-US`.replaceAll('"', '');
    request.headers.Authorization = `Bearer ${window.localStorage.getItem(`${envConfig.StoragePrefix}-token`)}`.replaceAll('"', '');
    return request;
  }, function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      const history = useHistory();
      history.push('/');
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  }
);

export default api;
