import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table, DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-duotone-svg-icons';
import { useJsonToCsv } from 'react-json-csv';
import MainPage from '../../shared-components/main-page/MainPage';
import { Spinner } from '../../shared-components/Spinner';
import { H1, H3, H4 } from '../../shared-components/typography/Title';
import Button from '../../shared-components/buttons/Button';
import { thousand, toCurrency, percentage } from '../../../functions';
import api from '../../../api/api';
import { notificationsAtom } from '../../../atoms/Atoms';
import SummaryCard from './shared-components/SummaryCard';

function TransdevInvoices() {
  const { saveAsCsv } = useJsonToCsv();
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingExportInvoice, setLoadingExportInvoice] = useState(false);
  const [loadingExportTrips, setLoadingExportTrips] = useState(false);

  function getTripsData(subsystem) {
    const subsystemKey = subsystem;
    const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate = dayjs(selectedDate).add(1, 'month').add(-1, 'minute').format('YYYY-MM-DDTHH:mm:ssZ');
    const tempData = { ...invoiceData };
    // Set loading
    for (let i = 0; i < tempData.serviceFees.length; i++) {
      const s = tempData.serviceFees[i];
      if (s.subsystem === subsystemKey) {
        s.loadingTrips = true;
      }  
    }
    setInvoiceData(tempData);
    setLoadingTrips(true);
    // get trips data
    api.get(`transdev/invoices/trips/${subsystemKey}?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => {
      setLoadingTrips(false);
      const tempData2 = { ...invoiceData };
      if (response.data.success) {
        for (let i = 0; i < tempData2.serviceFees.length; i++) {
          const s = tempData2.serviceFees[i];
          if (s.subsystem === subsystemKey) {
            s.allTrips = response.data.data;
          }  
        }
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
      for (let i = 0; i < tempData2.serviceFees.length; i++) {
        const s = tempData2.serviceFees[i];
        if (s.subsystem === subsystemKey) {
          s.loadingTrips = false;
        }  
      }
      setInvoiceData(tempData2);
    })
    .catch((error) => {
      setLoadingTrips(false);
      const tempData2 = { ...invoiceData };
      for (let i = 0; i < tempData2.serviceFees.length; i++) {
        const s = tempData2.serviceFees[i];
        if (s.subsystem === subsystemKey) {
          s.loadingTrips = false;
        }  
      }
      setInvoiceData(tempData2);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  const tripColumns = [
    {
      title: t('date'),
      key: 'serviceDate',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{dayjs(row.serviceDate).format('MMM DD, YYYY')}</span>
      ),
    },
    {
      title: t('route'),
      key: 'routeId',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.routeId}</span>
      ),
    },
    {
      title: t('pattern'),
      key: 'patternId',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.patternId}</span>
      ),
    },
    {
      title: t('trip_start'),
      key: 'tripStart',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{dayjs(row.tripStart).format('hh:mm A')}</span>
      ),
    },
    {
      title: t('trip_end'),
      key: 'tripEnd',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{dayjs(row.tripEnd).format('hh:mm A')}</span>
      ),
    },
    {
      title: t('start_location'),
      key: 'startLocation',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.startLocation}</span>
      ),
    },
    {
      title: t('end_location'),
      key: 'endLocation',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{row.endLocation}</span>
      ),
    },
    {
      title: t('revenue_hours'),
      key: 'revenueHours',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{thousand(row.revenueHours)}</span>
      ),
    },
    {
      title: t('revenue_miles'),
      key: 'revenueMiles',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <span>{thousand(row.revenueMiles)}</span>
      ),
    },
  ];

  const invoicesColumns = [
    {
      title: t('date'),
      key: 'serviceDate',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{dayjs(row.serviceDate).format('MMM, YYYY')}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('subsystem'),
      key: 'subsystem',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{row.subsystem}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('trips'),
      key: 'trips',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.trips)}</span>)}
          {row.isSummary && (<strong>{thousand(row.trips)}</strong>)}
        </>
      ),
    },
    {
      title: t('revenue_miles'),
      key: 'revenueMiles',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.revenueMiles)}</span>)}
          {row.isSummary && (<strong>{thousand(row.revenueMiles)}</strong>)}
        </>
      ),
    },
    {
      title: t('miles_rate'),
      key: 'milesRate',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{toCurrency(row.milesRate)}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('revenue_hours'),
      key: 'revenueHours',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.revenueHours)}</span>)}
          {row.isSummary && (<strong>{thousand(row.revenueHours)}</strong>)}
        </>
      ),
    },
    {
      title: t('hours_rate'),
      key: 'hoursRate',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{toCurrency(row.hoursRate)}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('subtotal'),
      key: 'total',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{toCurrency(row.total)}</span>)}
          {row.isSummary && (<strong>{toCurrency(row.total)}</strong>)}
        </>
      ),
    },
    {
      title: '',
      key: 'actions',
      align: 'center',
      isSummary: true,
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (
            <Button
              className="bg-primaryColor mb-4 px-2 mt-2"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              text={t('load_trips')}
              disabled={row.allTrips || (loadingTrips && !row.loadingTrips)}
              loading={row.loadingTrips}
              onClick={() => getTripsData(row.subsystem)}
            />
          )}
          {row.isSummary && ('')}
        </>
      ),
    },
  ];
  const incidentalsColumns = [
    {
      title: t('date'),
      key: 'date',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{dayjs(`${row.year}-${row.month}-01`).format('MMM, YYYY')}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('description'),
      key: 'description',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{row.description}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('amount'),
      key: 'total',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{toCurrency(row.total)}</span>)}
          {row.isSummary && (<strong>{toCurrency(row.total)}</strong>)}
        </>
      ),
    },
  ];
  const offsetColumns = [
    {
      title: t('date'),
      key: 'date',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{dayjs(`${row.date}-01`).format('MMM, YYYY')}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('subsystem'),
      key: 'subsystem',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{row.subsystem}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('checkpoints'),
      key: 'checkPoints',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.checkPoints)}</span>)}
          {row.isSummary && (<strong>{thousand(row.checkPoints)}</strong>)}
        </>
      ),
    },
    {
      title: t('compliant_checkpoints'),
      key: 'compliantCheckPoints',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.compliantCheckPoints)}</span>)}
          {row.isSummary && (<strong>{thousand(row.compliantCheckPoints)}</strong>)}
        </>
      ),
    },
    {
      title: t('non_compliant_checkpoints'),
      key: 'nonCompliantCheckPoints',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{thousand(row.nonCompliantCheckPoints)}</span>)}
          {row.isSummary && (<strong>{thousand(row.nonCompliantCheckPoints)}</strong>)}
        </>
      ),
    },
    {
      title: t('checkpoints_otp'),
      key: 'checkPointsOTP',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span>{percentage(row.checkPointsOTP, 1)}</span>)}
          {row.isSummary && (<strong>{percentage(row.checkPointsOTP, 1)}</strong>)}
        </>
      ),
    },
    {
      title: t('compliance'),
      key: 'offsetPercentage',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span className={row.offsetPercentage < 0 ? 'text-red-400' : 'text-green-600'}>{percentage(row.offsetPercentage, 1)}</span>)}
          {row.isSummary && ('')}
        </>
      ),
    },
    {
      title: t('offset'),
      key: 'total',
      align: 'center',
      className: 'text-sm',
      render: (row, object, index) => (
        <>
          {!row.isSummary && (<span className={row.total < 0 ? 'text-red-400' : 'text-green-600'}>{toCurrency(row.total)}</span>)}
          {row.isSummary && (<strong className={row.total < 0 ? 'text-red-400' : 'text-green-600'}>{toCurrency(row.total)}</strong>)}
        </>
      ),
    },
  ];

  function getData() {
    setLoading(true);
    setSummaryData(null);
    const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate = dayjs(selectedDate).add(1, 'month').add(-1, 'minute').format('YYYY-MM-DDTHH:mm:ssZ');
    api.get(`transdev/invoices?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => {
      setLoading(false);
      setInitialLoad(false);
      if (response.data.success) {
        setInvoiceData({
          serviceFees: _.map(response.data.data.serviceFees, (i) => ({
            ...i,
            // subsystemRef: useRef(null),
            serviceDate: !i.isSummary ? `${i.year}-${i.month}-01T00:00:00` : ''
          })),
          specialFees: _.map(response.data.data.specialFees, (i) => ({
            ...i,
            serviceDate: !i.isSummary ? `${i.year}-${i.month}-01T00:00:00` : ''
          })),
          offsets: _.map(response.data.data.offsets, (i) => ({
            ...i,
            date: !i.isSummary ? `${i.year}-${i.month}` : '',
            serviceDate: !i.isSummary ? `${i.year}-${i.month}-01T00:00:00` : ''
          })),
          incidentals: _.map(response.data.data.incidentals, (i) => ({
            ...i,
            date: !i.isSummary ? `${i.year}-${i.month}` : '',
            serviceDate: !i.isSummary ? `${i.year}-${i.month}-01T00:00:00` : ''
          }))
        });
        let totalRevenueMiles = 0;
        let totalRevenueHours = 0;
        let totalTrips = 0;
        let total = 0;
        for (let i = 0; i < response.data.data.serviceFees.length - 1; i++) {
          totalRevenueMiles += response.data.data.serviceFees[i].revenueMiles;
          totalTrips += response.data.data.serviceFees[i].trips;
          totalRevenueHours += response.data.data.serviceFees[i].revenueHours;
          total += response.data.data.serviceFees[i].total;
        }
        for (let i = 0; i < response.data.data.specialFees.length - 1; i++) {
          totalRevenueMiles += response.data.data.specialFees[i].revenueMiles;
          totalRevenueHours += response.data.data.specialFees[i].revenueHours;
          total += response.data.data.specialFees[i].total;
        }
        for (let i = 0; i < response.data.data.offsets.length - 1; i++) {
          total += response.data.data.offsets[i].total;
        }
        for (let i = 0; i < response.data.data.incidentals.length - 1; i++) {
          total += response.data.data.incidentals[i].total;
        }
        setSummaryData({
          totalRevenueMiles,
          totalRevenueHours,
          total,
          totalTrips
        })
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoading(false);
      setInitialLoad(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function exportPdf(type, title) {
    setLoadingExportInvoice(true);
    const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate = dayjs(selectedDate).add(1, 'month').add(-1, 'minute').format('YYYY-MM-DDTHH:mm:ssZ');
    api.get(`transdev/invoices/pdf?type=${type}&startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' })
      .then((response) => {
        setLoadingExportInvoice(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${title}_${selectedDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error(error);
        setLoadingExportInvoice(false);
      });
  }

  useEffect(() => {
    setSelectedDate(dayjs().format('MMM YYYY'));
  }, []);

  useEffect(() => {
    if (selectedDate) {
      getData();
    }
  }, [selectedDate]);

  return (
    <MainPage>
      <H1 text={`Transdev - ${t('invoices')}`} />
      {initialLoad && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!initialLoad && (
        <>
          <div className="flex float-right space-x-2">
            <DatePicker
              disabled={loading}
              picker="month"
              className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
              value={dayjs(selectedDate)}
              onChange={(obj) => {
                setSelectedDate(obj.format('MMM YYYY'));
              }}
              format="MMM YYYY"
            />
            <Button
              className="bg-primaryColor mb-4 px-2 mt-2"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              text={t('refresh')}
              loading={loading}
              onClick={() => getData()}
            />
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 w-full gap-4">
            <SummaryCard
              title={t('total_revenue_miles')}
              loading={loading}
              value={thousand(summaryData?.totalRevenueMiles)}
            />
            <SummaryCard
              title={t('total_revenue_hours')}
              loading={loading}
              value={thousand(summaryData?.totalRevenueHours)}
            />
            <SummaryCard
              title={t('total')}
              loading={loading}
              value={toCurrency(summaryData?.total)}
            />
          </div>

          {(invoiceData.serviceFees?.length > 1 ||
            invoiceData.specialFees?.length > 1 ||
            invoiceData.offsets?.length > 1 ||
            invoiceData.incidentals?.length > 1) && (
            <div className="flex mt-4">
              <Button
                className="ml-auto bg-primaryColor px-2"
                textColor="text-primaryTextColor"
                loaderColor="bg-primaryTextColor"
                borderColor="border-primaryBorderColor"
                text={(
                  <div>
                    <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                    {t('export_invoice')} (PDF)
                  </div>
                )}
                loading={loadingExportInvoice}
                onClick={() => {
                  exportPdf('', t('invoice'));
                }}
              />
            </div>
          )}

          <div className={invoiceData.serviceFees?.length > 1 ? '' : 'hidden'}>
            <Divider className="mt-6 pt-4" orientation="left">{t('regular_services')}</Divider>
            <Table
              bordered
              locale={{
                emptyText: t('no_data')
              }}
              loading={loading}
              pagination={false}
              rowKey="id"
              dataSource={invoiceData.serviceFees}
              columns={invoicesColumns}
              title={() => (
                <div className="flex space-x-2">
                  <Button
                    className="bg-primaryColor px-2 ml-auto"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (CSV)
                      </div>
                    )}
                    loading={loading}
                    onClick={() => {
                      const tempFields = { };
                      for (let i = 0; i < invoicesColumns.length; i++) {
                        tempFields[invoicesColumns[i].key] = invoicesColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(invoiceData.serviceFees, (c) => !c.isSummary),
                        fields: tempFields,
                        filename: `${t('regular_services')}_${selectedDate}`,
                      });
                    }}
                  />
                  <Button
                    className="bg-primaryColor px-2"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (PDF)
                      </div>
                    )}
                    loading={loadingExportInvoice}
                    onClick={() => {
                      exportPdf('regular', t('regular_services'));
                    }}
                  />
                </div>
              )}
              expandable={{
                expandRowByClick: false,
                rowExpandable: (record) => record.allTrips,
                expandedRowRender: (record, index) => (
                  <Table
                    bordered
                    key={record.id}
                    size="small"
                    dataSource={record.allTrips}
                    columns={tripColumns}
                    title={() => (
                      <div className="flex">
                        <H3 text={`${t('total_trips')}: ${thousand(record?.allTrips?.length)}`} />
                        <Button
                          className="bg-primaryColor px-2 ml-auto mr-2"
                          textColor="text-primaryTextColor"
                          loaderColor="bg-primaryTextColor"
                          borderColor="border-primaryBorderColor"
                          text={(
                            <div>
                              <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                              {t('export_trips')} (CSV)
                            </div>
                          )}
                          loading={loading}
                          onClick={() => {
                            const tempFields = { };
                            for (let i = 0; i < tripColumns.length; i++) {
                              tempFields[tripColumns[i].key] = tripColumns[i].title;
                            }
                            saveAsCsv({
                              data: record.allTrips,
                              fields: tempFields,
                              filename: `${t('regular_services')}_${record.subsystem}_${t('trips')}_${selectedDate}`,
                            });
                          }}
                        />
                        <Button
                          className="bg-primaryColor px-2"
                          textColor="text-primaryTextColor"
                          loaderColor="bg-primaryTextColor"
                          borderColor="border-primaryBorderColor"
                          text={(
                            <div>
                              <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                              {t('export_trips')} (PDF)
                            </div>
                          )}
                          loading={loadingExportTrips}
                          onClick={() => {
                            setLoadingExportTrips(true);
                            const startDate = dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ');
                            const endDate = dayjs(selectedDate).add(1, 'month').add(-1, 'minute').format('YYYY-MM-DDTHH:mm:ssZ');
                            api.get(`transdev/invoice/trips/${record.subsystem}/pdf?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' })
                              .then((response) => {
                                setLoadingExportTrips(false);
                                const href = URL.createObjectURL(response.data);
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', `${t('regular_services')}_${record.subsystem}_${t('trips')}_${selectedDate}.pdf`);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                              })
                              .catch((error) => {
                                console.error(error);
                                setLoadingExportTrips(false);
                              });
                          }}
                        />
                      </div>
                    )}
                  />
                )
              }}
            />
          </div>

          <div className={invoiceData.specialFees?.length > 1 ? '' : 'hidden'}>
            <Divider className="mt-6 pt-4" orientation="left">{t('special_fees')}</Divider>
            <Table
              bordered
              locale={{
                emptyText: t('no_data')
              }}
              loading={loading}
              pagination={false}
              rowKey="id"
              title={() => (
                <div className="flex space-x-2">
                  <Button
                    className="bg-primaryColor px-2 ml-auto"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (CSV)
                      </div>
                    )}
                    loading={loading}
                    onClick={() => {
                      const tempFields = { };
                      for (let i = 0; i < invoicesColumns.length; i++) {
                        tempFields[invoicesColumns[i].key] = invoicesColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(invoiceData.specialFees, (c) => !c.isSummary),
                        fields: tempFields,
                        filename: `${t('special_fees')}_${selectedDate}`,
                      });
                    }}
                  />
                  <Button
                    className="bg-primaryColor px-2"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (PDF)
                      </div>
                    )}
                    loading={loadingExportInvoice}
                    onClick={() => {
                      exportPdf('special', t('special_fees'));
                    }}
                  />
                </div>
              )}
              dataSource={invoiceData.specialFees}
              columns={_.filter(invoicesColumns, (c, i) => i !== invoicesColumns.length - 1)}
            />
          </div>

          <div className={invoiceData.offsets?.length > 1 ? '' : 'hidden'}>
            <Divider className="mt-6 pt-4" orientation="left">{t('offsets')}</Divider>
            <Table
              bordered
              locale={{
                emptyText: t('no_data')
              }}
              pagination={false}
              rowKey="id"
              title={() => (
                <div className="flex space-x-2">
                  <Button
                    className="bg-primaryColor px-2 ml-auto"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (CSV)
                      </div>
                    )}
                    loading={loading}
                    onClick={() => {
                      const tempFields = { };
                      for (let i = 0; i < offsetColumns.length; i++) {
                        tempFields[offsetColumns[i].key] = offsetColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(invoiceData.offsets, (c) => !c.isSummary),
                        fields: tempFields,
                        filename: `${t('offsets')}_${selectedDate}`,
                      });
                    }}
                  />
                  <Button
                    className="bg-primaryColor px-2"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (PDF)
                      </div>
                    )}
                    loading={loadingExportInvoice}
                    onClick={() => {
                      exportPdf('offset', t('offsets'));
                    }}
                  />
                </div>
              )}
              dataSource={invoiceData.offsets}
              columns={offsetColumns}
            />
          </div>

          <div className={invoiceData.incidentals?.length > 1 ? '' : 'hidden'}>
            <Divider className="mt-6 pt-4" orientation="left">{t('other')}</Divider>
            <Table
              bordered
              locale={{
                emptyText: t('no_data')
              }}
              loading={loading}
              pagination={false}
              rowKey="id"
              title={() => (
                <div className="flex space-x-2">
                  <Button
                    className="bg-primaryColor px-2 ml-auto"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (CSV)
                      </div>
                    )}
                    loading={loading}
                    onClick={() => {
                      const tempFields = { };
                      for (let i = 0; i < incidentalsColumns.length; i++) {
                        tempFields[incidentalsColumns[i].key] = incidentalsColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(invoiceData.incidentals, (c) => !c.isSummary),
                        fields: tempFields,
                        filename: `${t('other')}_${selectedDate}`,
                      });
                    }}
                  />
                  <Button
                    className="bg-primaryColor px-2"
                    textColor="text-primaryTextColor"
                    loaderColor="bg-primaryTextColor"
                    borderColor="border-primaryBorderColor"
                    text={(
                      <div>
                        <FontAwesomeIcon className="mr-2" icon={faCloudDownload} />
                        {t('export')} (PDF)
                      </div>
                    )}
                    loading={loadingExportInvoice}
                    onClick={() => {
                      exportPdf('other', t('other'));
                    }}
                  />
                </div>
              )}
              dataSource={invoiceData.incidentals}
              columns={incidentalsColumns}
            />
          </div>
        </>
      )}
    </MainPage>
  );
}

export default TransdevInvoices;
