import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../loader/Loader';

function Button({
  disabled,
  onClick,
  className,
  loading,
  text,
  loaderColor,
  textColor,
  borderColor,
  icon
}) {
  const borderClassNames = borderColor ? `border ${borderColor}` : ''
  return (
    <button
      type="button"
      disabled={disabled || loading}
      onClick={() => {
        if (!loading && onClick) {
          onClick();
        }
      }}
      className={`${className} ${borderClassNames} hover:scale-110 inline-flex text-sm h-10 flex justify-center items-center text-center disabled:opacity-25 text-sm transition duration-500 ease-in-out font-semibold rounded-full`}
    >
      {loading ? (
        <div className="px-2"><Loader color={loaderColor} /></div>
      ) : (
        <span className={`${textColor} mx-2`}>
          {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
          {text}
        </span>
      )}
    </button>
  );
}

export default Button;
