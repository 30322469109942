import React from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <Transition
        appear
        show
        key="footer"
        enter="transform transition duration-700 ease-in-out"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 -translate-x-0"
        leaveTo="opacity-0 -translate-x-full"
        className="flex justify-center items-center flex-shrink-0 mb-2 font-medium text-sm px-4"
      >
        <div>{t('copyright')} © {new Date().getFullYear()} {envConfig.SiteTitle}</div>
        <div className="flex flex-row p-2">
          <a href={`mailto:${envConfig.ContactEmail}`} className="text-accent hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">{t('contact_us')}</a>
          <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="hidden text-accent hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">Terminos de Uso</a>
        </div>
      </Transition>
    </footer>
  );
}

export default Footer;
