import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function DateSelector({
    label,
    name,
    className,
    required,
    value,
    disabled,
    onChange,
    uppercase,
    description,
    minDate,
    maxDate,
    error
  }) {
  return (
    <div className={`${className ?? ''} inputBox w-full text-xs tracking-tight space-y-1`}>
      <DatePicker
        dateFormat="MM/dd/yyyy"
        id={name}
        placeholder=""
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        className={`${uppercase ? 'uppercase' : ''} inputField disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed placeholder-gray-400 text-gray-900 text-base rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border ${error ? 'border-red-500' : 'border-gray-200'} focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500`}
        selected={value ? new Date(value) : value}
        onChange={onChange}
      />
      <span className={`${uppercase ? 'uppercase' : ''} inputLabel inputLabel-raised text-sm`}>{label}{required && <span className="text-red-500 ml-1">*</span>}</span>
      {description && <div className="text-xs ml-2 font-light text-gray-500">{description}</div>}
      {error && <div className="text-sm ml-2 font-light text-red-500">{error}</div>}
    </div>
  );
}

export default DateSelector
