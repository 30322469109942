import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../../shared-components/Spinner';

function SummaryCard({ icon, title, value, loading }) {
  const history = useHistory();
  return (
    <div className="shadow-md px-4 py-2 h-full hover:scale-120 bg-white">
      <div className="text-left">
        <div className="flex">
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="my-auto text-2xl mr-2"
            />
          )}
          <p className="text-xl">{title}</p>
        </div>
        {(!loading && value) && (
          <p className="mt-2 text-accentColor font-bold float-right text-md">{value}</p>
        )}
        {loading && (
          <div className="float-right flex items-center">
            <Spinner className="float-right text-accentColor" />
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryCard;
