import React from 'react';

function Input({
  id,
  name,
  value,
  onChange,
  autoComplete,
  required,
  placeholder,
  step,
  spellCheck,
  type,
  label,
  error,
  min,
  className,
  uppercase,
  autoCapitalize,
  onKeyDown,
  readOnly,
  disabled,
}) {
  return (
    <div className={`${className} inputBox w-full text-sm tracking-tight space-y-1`}>
      <input
        id={id}
        name={name}
        type={type}
        placeholder=""
        required={required}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        spellCheck={spellCheck}
        value={value}
        step={step}
        min={min}
        onChange={onChange}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        disabled={disabled}
        className={`${uppercase ? 'uppercase' : ''} inputField disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed placeholder-gray-400 text-gray-900 text-base rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border ${error ? 'border-red-500' : 'border-gray-200'} focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500`}
      />
      <span className={`${uppercase ? 'uppercase' : ''} inputLabel text-sm`}>{label}{required && <span className="text-red-500 ml-1">*</span>}</span>
      {error && <div className="text-sm ml-2 font-light text-red-500">{error}</div>}
    </div>
  );
}

export default Input;
