import React, { useState } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';

function LanguagePicker({ className }) {
  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const changeLanguage = function (lang) {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang).then(() => {
      window.localStorage.setItem(
        `${envConfig.StoragePrefix}-lang`,
        JSON.stringify(lang)
      );
    });
  };
  return (
    <Menu as="div" className={`${className} relative ml-2 text-3xl`}>
      <div className="relative flex ml-4">
        <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
        <FontAwesomeIcon
          icon={faLanguage}
          className="text-primaryColor"
        />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items className="absolute z-20 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
          <div className="rounded-lg shadow-lg overflow-hidden">
            <div className="relative bg-white p-4 space-y-4 text-sm">
              {_.map(languages, (lang) => (
                <Menu.Item
                  as="button"
                  key={lang.key}
                  type="button"
                  onClick={() => changeLanguage(lang.lang)}
                  className={`${selectedLanguage === lang.lang ? 'text-primaryColor' : 'text-gray-400'} group flex items-center hover:text-accent space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500`}
                >
                  <FontAwesomeIcon icon={faLanguage} className="w-4 h-4 text-2xl" />
                  <span>{lang.name}</span>
                </Menu.Item>
              ))}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu> 
  );
}

export default LanguagePicker;
