import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H2, H4 } from '../../shared-components/typography/Title';
import Button from '../../shared-components/buttons/Button';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import { encryptString } from '../../../functions';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import { Spinner } from '../../shared-components/Spinner';

function Profile() {
  const [user, setUser] = useRecoilState(userAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const formik = useFormik({
    initialValues: {
      fname: user?.firstName,
      lname: user?.lastName,
      email: user?.email,
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required(t('required_field')),
      lname: Yup.string().required(t('required_field')),
      email: Yup.string()
        .email(t('valid_email_validation'))
        .required(t('required_field'))
    }),
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const payload = {
        FirstName: values.fname,
        LastName: values.lname,
        Email: values.email
      };
      api.post('user/profile', payload)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      currentpassword: '',
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object().shape({
      currentpassword: Yup.string().required(t('required_field')),
      password: Yup.string().required(t('required_field')),
      passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        t('same_password')
      )
      .required(t('required_field')),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      api.post('auth/password/update', {
        CurrentPassword: encryptString(values.currentpassword),
        NewPassword: encryptString(values.password),
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          passwordFormik.setFieldValue('currentpassword', '');
          passwordFormik.setFieldValue('password', '');
          passwordFormik.setFieldValue('passwordConfirmation', '');
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
    },
  });

  async function handleImageChange(e) {
    e.preventDefault();
    if (e.target.files.length) {
      setLoadingImage(true);
      const data = new FormData();
      data.append('imageFile', e.target.files[0]);
      await api
        .post('user/profile/avatar', data)
        .then((response) => {
          setLoadingImage(false);
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: t('error'),
                description: response.data.error,
                error: true,
              },
            ]);
          } else {
            const tempUser = {
              ...response.data.data,
              firstName: formik.values.fname,
              lastName: formik.values.lname,
              email: formik.values.email,
            }
            setUser(tempUser);
            window.localStorage.setItem(
              `${envConfig.StoragePrefix}-user`,
              JSON.stringify(tempUser)
            );
          }
        })
        .catch((error) => {
          setLoadingImage(false);
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: error.message,
              error: true,
            },
          ]);
        });
    }
  }

  useEffect(() => {
    if (user) {
      formik.setFieldValue('fname', user?.firstName);
      formik.setFieldValue('lname', user?.lastName);
      formik.setFieldValue('email', user?.email);
    }
  }, [user]);

  return (
    <MainPage>
      <H1 text={t('profile')} />
      <Form className="grid gap-2 grid-cols-1 md:grid-cols-2 mt-4 mx-auto w-10/12 mb-8">
        <H2 text={t('update_personal_info')} />
        <div className="flex col-span-2 mx-auto my-4">
          <Spin spinning={loadingImage} indicator={<Spinner className="text-accentColor" />}>
            <div className="h-24 w-24 relative">
              <label htmlFor="upload-button">
                <img
                  src={user && user.avatar}
                  alt={user && user.completeName}
                  className="inline-block h-24 w-24 rounded-full cursor-pointer bg-gray-200"
                />
                <div className="cursor-pointer flex items-center justify-center rounded-full h-7 w-7 absolute -right-1 -bottom-2 bg-secondaryColor">
                  <FontAwesomeIcon icon={faPencil} className="text-sm text-primaryColor" />
                </div>
              </label>
              <input
                type="file"
                id="upload-button"
                className="hidden"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
          </Spin>
        </div>
        <Input
          className="col-span-2 mb-2"
          label={t('email')}
          required
          type="email"
          name="email"
          id="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <Input
          className="col-span-1 mb-2"
          label={t('name')}
          required
          type="text"
          name="fname"
          id="fname"
          autoComplete="given-name"
          autoCapitalize="true"
          value={formik.values.fname}
          onChange={formik.handleChange}
          error={
            formik.errors.fname && formik.touched.fname && formik.errors.fname
          }
        />
        <Input
          className="col-span-1 mb-2"
          label={t('last_name')}
          required
          type="text"
          name="lname"
          id="lname"
          autoComplete="family-name"
          autoCapitalize="true"
          value={formik.values.lname}
          onChange={formik.handleChange}
          error={
            formik.errors.lname && formik.touched.lname && formik.errors.lname
          }
        />
        <Button
          className="bg-primaryColor col-span-2 mt-2 mb-6 mx-auto w-9/12"
          textColor="text-primaryTextColor"
          loaderColor="bg-primaryTextColor"
          borderColor="border-primaryBorderColor"
          loading={loading}
          text={t('submit')}
          onClick={formik.submitForm}
        />
      </Form>
      <H4 className="text-center mb-4" text={t('or')} />
      <Form className="grid gap-2 grid-cols-1 mt-8 mx-auto w-10/12 mb-8">
        <H2 text={t('change_password')} />
        <Input
          label={t('current_password')}
          required
          id="currentpassword"
          name="currentpassword"
          type="password"
          className="mb-2"
          autoComplete="password"
          value={passwordFormik.values.currentpassword}
          onChange={passwordFormik.handleChange}
          error={
            passwordFormik.errors.password &&
            passwordFormik.touched.password &&
            passwordFormik.errors.password
          }
        />
        <Input
          label={t('new_password')}
          required
          id="password"
          name="password"
          type="password"
          className="mb-2"
          value={passwordFormik.values.password}
          onChange={passwordFormik.handleChange}
          error={
            passwordFormik.errors.password &&
            passwordFormik.touched.password &&
            passwordFormik.errors.password
          }
        />
        <Input
          label={t('confirm_password')}
          required
          id="passwordConfirmation"
          name="passwordConfirmation"
          type="password"
          value={passwordFormik.values.passwordConfirmation}
          onChange={passwordFormik.handleChange}
          error={
            passwordFormik.errors.passwordConfirmation &&
            passwordFormik.touched.passwordConfirmation &&
            passwordFormik.errors.passwordConfirmation
          }
        />
        <Button
          className="bg-primaryColor mt-4 mb-6 mx-auto w-9/12"
          textColor="text-primaryTextColor"
          loaderColor="bg-primaryTextColor"
          borderColor="border-primaryBorderColor"
          loading={loading}
          text={t('submit')}
          onClick={passwordFormik.submitForm}
        />
      </Form>
    </MainPage>
  );
}

export default Profile;
