import React from 'react';

function Checkbox({
  id,
  name,
  value,
  onChange,
  required,
  label,
  error,
  className,
  parentClassName,
  readOnly,
  disabled,
}) {
  return (
    <div className={`${parentClassName} tracking-tight space-y-1`}>
        <div className="flex">
          <input
            id={id}
            name={name}
            placeholder=""
            type="checkbox"
            required={required}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            className={`w-4 h-4 text-accent bg-gray-100 rounded border-gray-300 ${error ? 'border-1 border-red-500' : ''} focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
          />
          <label htmlFor={name} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label}
            {required && <span className="text-red-500 ml-1">*</span>}
          </label>
        </div>
      {error && <div className="text-sm ml-2 font-light text-red-500">{error}</div>}
    </div>
  );
}

export default Checkbox;
