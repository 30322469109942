import React from 'react';
import _ from 'lodash';

function Select({
  id,
  name,
  value,
  placeholderText,
  onChange,
  options,
  label,
  required,
  className,
  disabled,
  error
}) {
  return (
    <div className={`${className} inputBox text-sm tracking-tight space-y-1`}>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholderText}
        className={`inputField disabled:bg-gray-100 disabled:text-gray-400 placeholder-gray-400 text-gray-900 text-base rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border ${error ? 'border-red-500' : 'border-gray-200'} focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500`}
      >
        {_.map(options, (option) => (
          <option
            key={option.key}
            value={option.value}
            disabled={option.disabled}
          >
            {option.text}
          </option>
        ))}
      </select>
      <span className="inputLabel text-sm">{label}{required && <span className="text-red-500 ml-1">*</span>}</span>
      {error && <div className="text-sm ml-2 font-light text-red-500">{error}</div>}
    </div>
  );
}

export default Select;
