import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { faLockOpen } from '@fortawesome/pro-duotone-svg-icons';
import { userAtom, notificationsAtom, tokenAtom } from '../../../atoms/Atoms';
import { H1, H2 } from '../../shared-components/typography/Title';
import backdrop from '../../../assets/images/misc/login_backdrop.png';
import Button from '../../shared-components/buttons/Button';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Logo from '../../shared-components/logo/Logo';
import api from '../../../api/api';
import { encryptString } from '../../../functions';
import envConfig from '../../../envConfig';

function Login() {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    const { t } = useTranslation();
    const history = useHistory();
    const [user, setUser] = useRecoilState(userAtom);
    const setToken = useSetRecoilState(tokenAtom);
    const [loading, setLoading] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
      if (!loggingIn && user) {
        window.location = '/home';
      }
    }, [user]);

    function login(values) {
      setLoading(true);
      const payload = {
        Username: values.email,
        Password: encryptString(values.password)
      };
      api.post('auth/login', payload)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            if (!response.data.data.profile.acceptedOn) {
              setNotifications([
                ...notifications,
                {
                  title: t('error'),
                  description: t('your_account_not_approved_yet'),
                  error: true,
                },
              ]);
            } else if (response.data.data.profile.isBlocked) {
              setNotifications([
                ...notifications,
                {
                  title: t('error'),
                  description: t('your_account_is_blocked'),
                  error: true,
                },
              ]);
            } else {
              setLoggingIn(true);
              localStorage.setItem(
                `${envConfig.StoragePrefix}-user`,
                JSON.stringify(response.data.data.profile)
              );
              localStorage.setItem(
                `${envConfig.StoragePrefix}-token`,
                response.data.data.accessToken
              );
              localStorage.setItem(
                `${envConfig.StoragePrefix}-token-date`,
                dayjs().toString()
              );
              setToken(response.data.data.accessToken);
              localStorage.setItem(`${envConfig.StoragePrefix}-impersonate`, false);
              return response;
            }
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('error'),
                description: response.data.error,
                error: true,
              },
            ]);
          }
        })
        .then((response) => {
          if (response) {
            setUser(response.data.data.profile);
            if (response.data.data.profile.forcePasswordReset) {
              history.push('/updatepass');
            } else {
              history.push('/home');
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: error.message,
              error: true,
            },
          ]);
        });
    }

    const formik = useFormik({
      initialValues: {
        email: '',
        password: ''
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email(t('valid_email_validation'))
          .required(t('required_field')),
        password: Yup.string().required(t('required_field')),
      }),
      onSubmit: (values, { setSubmitting }) => login(values),
    });

    return (
      <div className="grid mx-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-6">
        <div className="flex col-span-1 lg:col-span-4 flex-col w-full mt-12 md:mt-48 justify-center items-center">
          <Logo
            className="md:hidden w-64 hover:scale-110"
          />
          <H1 className="my-4 text-center" text={t('welcome_message')} />
          <img className="hidden md:block bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
        <div className="flex md:col-span-1 lg:col-span-2 flex-col w-full md:mt-48 justify-center items-center">
          <Logo
            className="hidden md:block md:w-64 hover:scale-110"
          />
          <H2 className="my-4 text-center" text={t('login_to_interact')} />
          <Form className="mt-4 w-10/12">
            <Input
              label={t('email')}
              required
              type="email"
              name="email"
              id="email"
              className="mb-8"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.errors.email && formik.touched.email && formik.errors.email
              }
            />
            <Input
              label={t('password')}
              required
              id="password"
              name="password"
              type="password"
              className="mb-8"
              autoComplete="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.errors.password &&
                formik.touched.password &&
                formik.errors.password
              }
            />
          </Form>
          <Button
            className="bg-primaryColor mb-6 w-9/12"
            textColor="text-primaryTextColor"
            loaderColor="bg-primaryTextColor"
            borderColor="border-primaryBorderColor"
            loading={loading}
            text={t('sign_in')}
            onClick={formik.submitForm}
          />
          <Button
            disabled={loading}
            className="bg-secondaryColor mb-6 w-9/12"
            textColor="text-secondaryTextColor"
            borderColor="border-secondaryBorderColor"
            text={t('create_account')}
            onClick={() => history.push('/register')}
          />
          <Button
            disabled={loading}
            className="mb-6 w-9/12"
            textColor="text-secondaryTextColor"
            text={t('forgot_password')}
            icon={faLockOpen}
            onClick={() => history.push('/recover')}
          />
        </div>
        <div className="flex md:hidden col-span-1 flex-col w-full mb-4 justify-center items-center">
          <img className="bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
      </div>
    );
}

export default Login;
