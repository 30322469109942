export const phoneNumber = (phone) => {
    const cleaned = `${phone}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };
  
  // Desktop animation variants for backdrop
  export const backdropVariants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  
  // animation variants for details-modal
  export const modalVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        type: 'spring',
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 1,
        type: 'spring',
      },
    },
  };
  
  // animation variants for sidebar
  export const sidebarVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      x: '100%',
      transition: {
        duration: 0.5,
        type: 'spring',
      },
    },
  };
  
  // animation variants for product list
  export const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2, delay: 1 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1, delay: 1 },
    },
  };

  export const frequencies = [
    { id: 0, name: 'daily' },
    { id: 1, name: 'weekly' },
    { id: 2, name: 'monthly' },
    { id: 3, name: 'yearly' },
    { id: 4, name: 'on-demand' },
  ]

  export const fileColumnDataType = [
    { id: 0, name: 'text' },
    { id: 1, name: 'datetime' },
    { id: 2, name: 'date' },
    { id: 3, name: 'time' },
    { id: 4, name: 'number' },
    { id: 5, name: 'currency' },
  ]

  export const uploadHistoryFilters = [
    { id: 0, name: 'all' },
    { id: 1, name: 'pending' },
    { id: 2, name: 'processing' },
    { id: 3, name: 'processed' },
    { id: 4, name: 'failed' },
  ]

  export const fareboxCollectionSubmissionFilters = [
    { id: 0, name: 'all' },
    { id: 1, name: 'pending' },
    { id: 2, name: 'in_review' },
    { id: 3, name: 'needs_more_info' },
    { id: 4, name: 'accepted' },
    { id: 5, name: 'rejected' },
  ]

  export const fareboxCollectionSubmissionStatus = [
    { id: 0, name: 'pending' },
    { id: 1, name: 'in_review' },
    { id: 2, name: 'needs_more_info' },
    { id: 3, name: 'accepted' },
    { id: 4, name: 'rejected' },
  ]

  export const incidentalsSubmissionStatus = [
    { id: 0, name: 'pending' },
    { id: 1, name: 'in_review' },
    { id: 2, name: 'needs_more_info' },
    { id: 3, name: 'accepted' },
    { id: 4, name: 'rejected' },
    { id: 5, name: 'payment_accepted' },
    { id: 6, name: 'payment_rejected' },
  ]

  export const fareboxCollectionSubmissionAttachmentFileTypes = [
    { id: 0, name: 'bank_slip' },
    { id: 1, name: 'order_packing_slip' },
    { id: 2, name: 'both' },
    { id: 3, name: 'other' }
  ]

  export const storageFileProcessesFilters = [
    { id: 0, name: 'all' },
    { id: 1, name: 'pending' },
    { id: 2, name: 'processing' },
    { id: 3, name: 'processed' },
    { id: 4, name: 'failed' },
  ]

  export const storageFileProcessesStatus = [
    { id: 0, name: 'pending' },
    { id: 1, name: 'processing' },
    { id: 2, name: 'processed' },
    { id: 3, name: 'failed' },
  ]
