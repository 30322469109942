import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { H1 } from '../../shared-components/typography/Title';
import backdrop from '../../../assets/images/misc/error_backdrop.png';
import Button from '../../shared-components/buttons/Button';

function NotFound() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
      <div className="flex flex-col w-full mt-24 md:mt-48 justify-center items-center">
        <H1 className="my-4 text-center" text={t('404_error_message')} />
        <Button
          className="bg-primaryColor max-w-xs w-full mb-6 mx-8"
          textColor="text-primaryTextColor"
          borderColor="border-primaryBorderColor"
          text={t('go_back')}
          onClick={() => { history.push('/home'); }}
        />
        <img className="bottom-0 mt-auto" alt={t('404_error_image_alt')} src={backdrop} />
      </div>
    );
}

export default NotFound;
