import React from 'react';

function Form({ children, className }) {
  return (
    <form
      noValidate
      autoComplete="off"
      className={className}
    >
      {children}
    </form>
  );
}

export default Form;
