import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  DatePicker,
  Table,
  Tag,
  Avatar
} from 'antd';
import Link from 'antd/es/typography/Link';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import MainPage from '../../shared-components/main-page/MainPage';
import { H1, H4 } from '../../shared-components/typography/Title';
import api from '../../../api/api';
import { Spinner } from '../../shared-components/Spinner';
import { uploadHistoryFilters } from '../../../utils';
import { uploadHistoryStatusToString } from '../../../functions';
import Select from '../../shared-components/form/Select';
import Button from '../../shared-components/buttons/Button';

function UploadHistory() {
  const { RangePicker } = DatePicker;
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [loadingOperators, setLoadingOperators] = useState(true);

  const [loadingRequiredFiles, setLoadingRequiredFiles] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedDate, setSelectedDate] = useState('');

  const [loading, setLoading] = useState(false);
  const [totalHistory, setTotalHistory] = useState(0);
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [historyFilter, setHistoryFilter] = useState(0);

  function getStatusColor(status) {
    if (status === 2) {
      return 'green';
    }
    if (status === 3) {
      return 'red';
    }
    return '';
  }

  function getOperators() {
    setLoadingOperators(true);
    api.get('operators/user')
    .then((response) => {
      if (response.data.success) {
        setLoadingOperators(false);
        setOperators(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingOperators(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function getRequiredFiles() {
    setLoadingRequiredFiles(true);
    api.get(`operators/${selectedOperator?.id}/files/active`)
    .then((response) => {
      setLoadingRequiredFiles(false);
      if (response.data.success) {
        setRequiredFiles(response.data.data);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
      setLoadingRequiredFiles(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  function loadHistory(selectedPage) {
    setCurrentPage(selectedPage);
    const startDate = dayjs(selectedDate);
    const endDate = startDate.endOf('month');
    setLoading(true);
    const utcOffset = startDate.utcOffset();
    const startString = startDate.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    const endString = endDate.add(-1 * utcOffset, 'minute').format('YYYY-MM-DDThh:mm:ss');
    api.get(`operators/files/uploaded?filter=${historyFilter}&page=${currentPage}&size=${pageSize}&operatorId=${selectedOperator?.id}&startDate=${startString}&endDate=${endString}${selectedFile ? `&selectedFileId=${selectedFile?.id}` : ''}`)
    .then((response) => {
      if (response.data.success) {
        setLoading(false);
        setHistory(response.data.data.files);
        setTotalHistory(response.data.data.total);
      } else {
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: response.data.error,
            error: true,
          }
        ]);
      }
    })
    .catch((error) => {
        setLoading(false);
      setNotifications([
        ...notifications,
        {
          title: t('error'),
          description: error.message,
          error: true,
        }
      ]);
    });
  }

  useEffect(() => {
    getOperators();
    setSelectedDate(dayjs().format('MMM YYYY'));
  }, []);

  useEffect(() => {
    if (operators.length > 0) {
      setSelectedOperator(operators[0]);
    } else {
      setSelectedOperator(null);
    }
  }, [operators]);

  useEffect(() => {
    setRequiredFiles([]);
    if (selectedOperator) {
      getRequiredFiles();
    }
  }, [selectedOperator]);

  useEffect(() => {
    if (requiredFiles.length > 0) {
      loadHistory(0);
      if (requiredFiles.length === 1) {
        setSelectedFile(requiredFiles[0]);
      }
    } else {
      setSelectedFile(null);
    }
  }, [requiredFiles]);

  useEffect(() => {
    if (requiredFiles.length > 1) {
      loadHistory(0);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (requiredFiles.length > 0) {
      loadHistory(0);
    }
  }, [pageSize, historyFilter, selectedDate]);

  return (
    <MainPage>
      <H1 text={t('document_upload_history')} />
      {loadingOperators && (
        <div className="w-full h-full flex items-center">
          <Spinner className="mx-auto text-accentColor" big />
        </div>
      )}
      {!loadingOperators && (
        <div>
          <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {operators?.length > 1 && (
              <Select
                className="col-span-1 mt-2"
                label={t('operator')}
                id="operator"
                name="operator"
                disabled={loading}
                value={selectedOperator?.id}
                onChange={(obj) => {
                  const filter = _.filter(operators, (op) => op.id === obj.target.value);
                  if (filter.length > 0) {
                    setSelectedOperator(filter[0]);
                  }
                }}
                options={_.map(operators, (op) => ({
                  key: op.id,
                  value: op.id,
                  text: i18n.language === 'en' ? op.nameEn : op.nameEs,
                }))}
              />
            )}
            <Select
              className={`${operators?.length > 1 ? 'col-span-1' : 'col-span-2'} mt-2 md:ml-2`}
              label={t('file')}
              id="file"
              name="file"
              disabled={requiredFiles?.length === 0 ||
                        requiredFiles?.length === 1 ||
                        loadingRequiredFiles || loading}
              value={selectedFile?.id}
              onChange={(obj) => {
                console.log(obj.target.value);
                if (obj.target.value !== t('any')) {
                  const filter = _.filter(requiredFiles, (file) => file.id === obj.target.value);
                  if (filter.length > 0) {
                    setSelectedFile(filter[0]);
                  }
                } else {
                  setSelectedFile(null);
                }
              }}
              options={_.map(requiredFiles?.length > 1 ? [{
                id: null,
                nameEn: t('any'),
                nameEs: t('any')
              }, ...requiredFiles] : requiredFiles, (file) => ({
                key: file.id,
                value: file.id,
                text: i18n.language === 'en' ? file.nameEn : file.nameEs,
              }))}
            />
            <Select
              className="col-span-1 mt-2 md:ml-2"
              disabled={loading}
              label={t('filter')}
              id="filter"
              name="filter"
              value={historyFilter}
              onChange={(obj) => {
                setHistoryFilter(parseInt(obj.target.value, 10));
              }}
              options={_.map(uploadHistoryFilters, (f) => ({
              key: f.id,
              value: f.id,
              text: t(f.name),
              }))}
            />
            <div className="col-span-1 md:mr-2">
              <DatePicker
                disabled={loading}
                picker="month"
                className="w-full h-11 mt-2 md:ml-2 lg:ml-0 xl:ml-2 bg-transparent"
                value={dayjs(selectedDate)}
                onChange={(obj) => {
                  setSelectedDate(obj.format('MMM YYYY'));
                }}
                format="MMM YYYY"
              />
            </div>
            <Button
              className="bg-primaryColor col-span-1 md:col-span-2 lg:col-start-2 xl:col-span-1 xl:col-start-0 md:ml-2 mt-2"
              textColor="text-primaryTextColor"
              loaderColor="bg-primaryTextColor"
              borderColor="border-primaryBorderColor"
              loading={loading}
              disabled={requiredFiles.length < 1}
              text={t('refresh')}
              onClick={() => loadHistory(currentPage)}
            />
          </div>
          <div className="mt-2">
            {requiredFiles?.length === 0 && !loadingRequiredFiles && (
              <H4
                text={t('no_required_files')}
                className="mx-auto text-gray-400 text-center mt-32 col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4"
              />
            )}
            {(loadingRequiredFiles) && (
              <div className="w-full flex items-center">
                <Spinner className="mx-auto mt-44 text-accentColor" big />
              </div>
            )}
            {requiredFiles?.length > 0 && (
              <Table
                bordered
                locale={{
                  emptyText: t('no_data')
                }}
                rowKey="id"
                loading={loading}
                className="mt-2"
                dataSource={history}
                columns={[
                  {
                    title: t('file'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{i18n.language === 'en' ? row.requirementFile.nameEn : row.requirementFile.nameEs}</span>
                    ),
                  },
                  {
                    title: t('upload_name'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <Link target="_blank" href={row?.storageUrl}>
                        {row.fileName}
                      </Link>
                    ),
                  },
                  {
                    title: t('status'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <Tag color={getStatusColor(row.status)}>
                        {t(uploadHistoryStatusToString(row.status))}
                      </Tag>
                    ),
                  },
                  {
                    title: t('rows_found'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.rowsFound ?? '-'}</span>
                    ),
                  },
                  {
                    title: t('uploaded_on'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{dayjs(row.createdAt).format('MMM DD, YYYY hh:mm A')}</span>
                    ),
                  },
                  {
                    title: t('uploaded_by'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                    <>
                      {row.uploadedBy ?
                        <div>
                          <Avatar src={row.uploadedBy?.avatar} />
                          <div>{row.uploadedBy?.completeName}</div>
                        </div> : <span>-</span>}
                    </>),
                  },
                  {
                    title: t('message'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <span>{row.message ?? '-'}</span>
                    ),
                  },
                  {
                    title: t('duplicate_file'),
                    align: 'center',
                    className: 'text-sm',
                    render: (row, object, index) => (
                      <>
                        {row.duplicatesStorageUrl && (
                          <Link target="_blank" href={row?.duplicatesStorageUrl}>
                            {t('duplicates')}
                          </Link>
                        )}
                        {!row.duplicatesStorageUrl && (
                          <span>-</span>
                        )}
                      </>
                    ),
                  }
                ]}
              />
            )}
          </div>
        </div>
      )}
    </MainPage>
  );
}

export default UploadHistory;
