import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClickAway } from 'react-use';
import { NavLink, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSignIn, faUserPlus } from '@fortawesome/pro-duotone-svg-icons';
import { menuSideBarAtom, userAtom } from '../../../atoms/Atoms';
import { backdropVariants, sidebarVariants } from '../../../utils';
import Logo from '../logo/Logo';
import LanguagePicker from '../language-picker/LanguagePicker';
import AuthenticatedSideBar from './shared-components/AuthenticatedSideBar';

function MenuSideBar() {
  const { t } = useTranslation();
  const ref = useRef();
  const user = useRecoilValue(userAtom);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuSideBarAtom);

  useClickAway(ref, () => {
    setIsMenuOpen(false);
  });

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
          >
            <motion.div
              ref={ref}
              variants={sidebarVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white"
            >
              <div className="px-4 py-6 flex items-center justify-between bg-gray-100">
                <button
                  aria-label="home menu"
                  type="button"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Logo
                    className="w-36 md:w-52 hover:scale-110"
                  />
                </button>
                <LanguagePicker />
                <button
                  aria-label="close menu"
                  type="button"
                  onClick={() => setIsMenuOpen(false)}
                  className="hover:opacity-75 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                </button>
              </div>
              {user && <AuthenticatedSideBar />}
              {!user && (
                <div>
                  <NavLink
                    exact
                    to="/"
                    activeClassName="bg-gray-100"
                    onClick={() => setIsMenuOpen(false)}
                    className="px-4 py-3 flex items-center space-x-2 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faSignIn} />
                    <div>{t('sign_in')}</div>
                  </NavLink>
                  <NavLink
                    exact
                    to="/register"
                    activeClassName="bg-gray-100"
                    onClick={() => setIsMenuOpen(false)}
                    className="px-4 py-3 flex items-center space-x-2 text-sm tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faUserPlus} />
                    <div>{t('create_account')}</div>
                  </NavLink>
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default MenuSideBar;
