import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { userAtom, notificationsAtom } from '../../../atoms/Atoms';
import { H2, H4 } from '../../shared-components/typography/Title';
import backdrop from '../../../assets/images/misc/login_backdrop.png';
import Button from '../../shared-components/buttons/Button';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Logo from '../../shared-components/logo/Logo';
import api from '../../../api/api';

function Recover() {
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    const { t } = useTranslation();
    const history = useHistory();
    const user = useRecoilValue(userAtom);
    const [loading, setLoading] = useState(false);

    if (user) {
      window.location = '/home';
    }

    function submit(values) {
      setLoading(true);
      api.post('auth/request/reset', { Email: values.email })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: t('yay'),
              description: response.data.message,
              error: false,
            },
          ]);
          history.push('/');
        } else {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: t('error'),
            description: error.message,
            error: true,
          },
        ]);
      });
    }

    const formik = useFormik({
      initialValues: {
        email: ''
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email(t('valid_email_validation'))
          .required(t('required_field')),
      }),
      onSubmit: (values, { setSubmitting }) => submit(values),
    });

    return (
      <div className="grid mx-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-6">
        <div className="flex col-span-1 lg:col-span-4 flex-col w-full mt-12 md:mt-48 justify-center items-center">
          <img className="hidden md:block bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
        <div className="flex md:col-span-1 lg:col-span-2 flex-col w-full md:mt-48 justify-center items-center">
          <Logo
            className="w-64 hover:scale-110"
          />
          <H2 className="my-4 text-center" text={t('enter_email_to_send_instructions')} />
          <Form className="mt-4 w-10/12">
            <Input
              label={t('email')}
              required
              type="email"
              name="email"
              id="email"
              className="mb-8"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.errors.email && formik.touched.email && formik.errors.email
              }
            />
          </Form>
          <Button
            className="bg-primaryColor mb-6 w-9/12"
            textColor="text-primaryTextColor"
            loaderColor="bg-primaryTextColor"
            borderColor="border-primaryBorderColor"
            loading={loading}
            text={t('submit')}
            onClick={formik.submitForm}
          />
          <H4 className="mb-4" text={t('or')} />
          <Button
            disabled={loading}
            className="bg-secondaryColor mb-6 w-9/12"
            textColor="text-secondaryTextColor"
            borderColor="border-secondaryBorderColor"
            text={t('go_back')}
            onClick={() => history.push('/')}
          />
        </div>
        <div className="flex md:hidden col-span-1 flex-col w-full mb-4 justify-center items-center">
          <img className="bottom-0 mt-auto" alt={t('welcome_image_alt')} src={backdrop} />
        </div>
      </div>
    );
}

export default Recover;
