import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { userAtom, mainPageInitializeAtom } from '../../../atoms/Atoms';
import AuthenticatedSideBar from '../menu-sidebar/shared-components/AuthenticatedSideBar';
import { Spinner } from '../Spinner';
import { backdropVariants } from '../../../utils';
import envConfig from '../../../envConfig';

function MainPage({ children }) {
  const user = useRecoilValue(userAtom);
  const [impersonate] = useLocalStorage(`${envConfig.StoragePrefix}-impersonate`);
  const [initializing, setInitializing] = useRecoilState(mainPageInitializeAtom);
  
  useEffect(() => {
    setTimeout(() => setInitializing(false), 250);
  }, []);

  useEffect(() => {
    if (!initializing) {
      if (user?.forcePasswordReset && !impersonate) {
        window.location = '/updatepass';
      } else if (!user) {
        window.location = '/';
      }
    }
  }, [initializing]);

  return (
    <AnimatePresence>
      <div className="flex w-full h-full divide-x">
        <div className="px-4 py-2 hidden md:inline-block w-80 h-full">
          <AuthenticatedSideBar />
        </div>
        <div className="w-full px-4">
          {children}
        </div>
      </div>
      {/* {initializing && (
        <motion.div
          variants={backdropVariants}
          initial="visible"
          animate="hidden"
          exit="visible"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <div className="loading-screen loading-screen-full-white">
            <Spinner className="text-accentColor" big />
          </div>
        </motion.div>
      )} */}
      <motion.div
        variants={backdropVariants}
        initial="visible"
        animate="hidden"
        exit="visible"
        className={`${initializing ? 'fixed' : 'hidden'} bg-gray-800 inset-0 bg-opacity-75 z-20`}
      >
        <div className="loading-screen loading-screen-full-white">
          <Spinner className="text-accentColor" big />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default MainPage;
