import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

function HomeCard({ icon, title, description, route }) {
  const history = useHistory();
  return (
    <>
      {route && (
        <button
          type="button"
          className="shadow-md px-4 py-4 h-full bg-white hover:shadow-lg hover:bg-gray-100"
          onClick={() => history.push(route)}
        >
          <div className="text-left">
            <div className="flex">
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className="my-auto text-2xl mr-2"
                />
              )}
              <p className="text-accentColor font-bold text-xl">{title}</p>
            </div>
            {description && (
              <p className="mt-2 text-md">{description}</p>
            )}
          </div>
        </button>
      )}
      {!route && (
        <div className="shadow-md px-4 py-2 h-full hover:scale-120 bg-white">
          <div className="text-left">
            <div className="flex">
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className="my-auto text-2xl mr-2"
                />
              )}
              <p className="text-accentColor font-bold text-xl">{title}</p>
            </div>
            {description && (
              <p className="mt-2 text-md">{description}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default HomeCard;
