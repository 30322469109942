const envConfig = {
  ApiUrl: process.env.REACT_APP_API_URL ?? '#{REACT_APP_API_URL}#',
  ApiKey: process.env.REACT_APP_API_KEY ?? '#{REACT_APP_API_KEY}#',
  StoragePrefix: process.env.REACT_APP_PREFIX ?? '#{REACT_APP_PREFIX}#',
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? '#{REACT_APP_DEFAULT_LANGUAGE}#',
  ContactEmail: process.env.REACT_APP_CONTACT_EMAIL ?? '#{REACT_APP_CONTACT_EMAIL}#',
  SupportEmail: process.env.REACT_APP_SUPPORT_EMAIL ?? '#{REACT_APP_SUPPORT_EMAIL}#',
  SiteTitle: process.env.REACT_APP_TITLE ?? '#{REACT_APP_TITLE}#',
  SiteDescription: process.env.REACT_APP_DESCRIPTION ?? '#{REACT_APP_DESCRIPTION}#',
  TokenLifetimeHours: process.env.REACT_APP_TOKEN_LIFETIME_HOURS ?? '#{REACT_APP_TOKEN_LIFETIME_HOURS}#',
  EncryptionKey: process.env.REACT_APP_ENCRYPTION_KEY ?? '#{REACT_APP_ENCRYPTION_KEY}#',
  ServerConfigurationString: process.env.REACT_APP_SERVER_CONFIG_STRING ?? '#{REACT_APP_SERVER_CONFIG_STRING}#',
  AmaOperatorId: process.env.REACT_APP_AMA_OPERATOR_ID ?? '#{REACT_APP_AMA_OPERATOR_ID}#',
  TransdevOperatorId: process.env.REACT_APP_TRANSDEV_OPERATOR_ID ?? '#{REACT_APP_TRANSDEV_OPERATOR_ID}#',
  HideMenuLocations: ['/register', '/recover', '/reset', '/updatepass'],
  HideHeaderLogo: ['/', '/register', '/recover', '/reset', '/updatepass'],
  Roles: [
    { index: 1, key: 'GlobalAdmin', name: 'global_admin' },
    { index: 2, key: 'Admin', name: 'admin' },
    { index: 4, key: 'CustomerSupport', name: 'customer_support' },
    { index: 8, key: 'RouteValidation', name: 'route_validation' },
    { index: 16, key: 'OperatorAdmin', name: 'operator_admin' },
    { index: 32, key: 'OperatorManager', name: 'operator_manager' }
  ]
};

export default envConfig;
