import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo.png';
import envConfig from '../../../envConfig';

function Logo({ parentClassName, className }) {
  return (
    <NavLink className={parentClassName} key="logohome" to="/">
      <img className={className} src={logo} alt={envConfig.SiteTitle} />
    </NavLink>    
  );
}

export default Logo;
