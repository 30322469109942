import './App.css';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useLocalStorage, useMount, useLocation } from 'react-use';
import {
  useRecoilValue, useRecoilState, useSetRecoilState,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from '../routes/index';
import {
  tokenAtom,
  userAtom,
  userPrivilagesAtom,
  userOperatorsAtom
} from '../atoms/Atoms';
import ProtectedRoute from '../routes/ProtectedRoutes';
import Header from './shared-components/header/Header';
import Footer from './shared-components/footer/Footer';
import Notifications from './shared-components/notifications/Notifications';
import envConfig from '../envConfig';
import MenuSideBar from './shared-components/menu-sidebar/MenuSideBar';
import api from '../api/api';
import { Spinner } from './shared-components/Spinner';
import { backdropVariants } from '../utils';

function App() {
  if (!String.prototype.format) {
    String.prototype.format = function () {
      const args = arguments;
      return this.replace(/{(\d+)}/g, function (match, number) { 
        return typeof args[number] !== 'undefined'
          ? args[number]
          : match;
      });
    };
  }

  const { i18n } = useTranslation();
  const location = useLocation();
  const setToken = useSetRecoilState(tokenAtom);
  const setUserPrivilages = useSetRecoilState(userPrivilagesAtom);
  const setUserOperators = useSetRecoilState(userOperatorsAtom);
  const [language] = useLocalStorage(`${envConfig.StoragePrefix}-lang`);
  const [token] = useLocalStorage(`${envConfig.StoragePrefix}-token`);
  const [storageTokenDate] = useLocalStorage(`${envConfig.StoragePrefix}-token-date`);
  const [storageUser] = useLocalStorage(`${envConfig.StoragePrefix}-user`);
  const [user, setUser] = useRecoilState(userAtom);
  const [fetchedState, setFetchedState] = useState(false);
  const [initializing, setInitializing] = useState(true);

  function updateLanguage() {
    if (user) {
      api.post('user/profile', {
        preferredLanguage: i18n.language === 'en' ? 0 : 1
      });
    }
  }

  function getCurrentUserState() {
    if (user && !fetchedState) {
      setFetchedState(true);
      api.get('auth/refreshstate')
        .then((response) => {
          if (response.data.data.isBlocked) {
            window.localStorage.removeItem(`${envConfig.StoragePrefix}-user`);
            window.localStorage.removeItem(`${envConfig.StoragePrefix}-token`);
            setToken(null);
            setUser(null);
            window.location = '/';
          } else {
            setUser(response.data.data);
            localStorage.setItem(
              `${envConfig.StoragePrefix}-user`,
              JSON.stringify(response.data.data)
            );
          }
        }).catch((err) => {
          window.localStorage.removeItem(`${envConfig.StoragePrefix}-user`);
          window.localStorage.removeItem(`${envConfig.StoragePrefix}-token`);
          setToken(null);
          setUser(null);
          window.location = '/';
        });
    }
    if (user) {
      api.get('auth/refreshtoken')
        .then((response) => {
          setToken(response.data.data);
          localStorage.setItem(
            `${envConfig.StoragePrefix}-token-date`,
            dayjs().toString()
          );
        }).catch((err) => {
          // console.log('error getting token');
        });
    }
  }

  function getUserOperators() {
    api.get('operators/user')
    .then((response) => {
      if (response.data.success) {
        setUserOperators(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error?.message);
    });
  }

  useEffect(() => {
    getCurrentUserState();
    if (user) {
      getUserOperators();
      setUserPrivilages({
        hasGlobalPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin').length >= 1,
        hasAdminPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin' || r === 'Admin').length >= 1,
        hasCustomerSupportPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin' || r === 'Admin' || r === 'CustomerSupport').length >= 1,
        hasOperatorAdminPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin' || r === 'OperatorAdmin').length >= 1,
        hasOperatorManagerPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin' || r === 'OperatorAdmin' || r === 'OperatorManager').length >= 1,
        hasRouteValidationPrivilages: _.filter(user?.roles, (r) => r === 'GlobalAdmin' || r === 'RouteValidation' || r === 'OperatorAdmin' || r === 'OperatorManager').length >= 1
      });
    } else {
      setUserOperators([]);
    }
  }, [user]);

  useEffect(() => {
    updateLanguage();
  }, [language, i18n.language, user]);

  useEffect(() => {
    setTimeout(() => setInitializing(false), 1500);
  }, []);
 
  useMount(() => {
    if (language) {
      localStorage.setItem(`${envConfig.StoragePrefix}-lang`, JSON.stringify(language))
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(envConfig.DefaultLanguage);
      localStorage.setItem(`${envConfig.StoragePrefix}-lang`, JSON.stringify(envConfig.DefaultLanguage))
    }

    let forceLogout = false;
    if (storageTokenDate) {
      const tokenLifetimeHours = parseInt(envConfig.TokenLifetimeHours, 10);
      const lastDate = dayjs(storageTokenDate);
      const hourDifference = dayjs().diff(lastDate, 'hour');
      forceLogout = hourDifference >= tokenLifetimeHours;
    }

    if (forceLogout) {
      window.localStorage.removeItem(`${envConfig.StoragePrefix}-user`);
      window.localStorage.removeItem(`${envConfig.StoragePrefix}-token`);
      setToken(null);
      setUser(null);
      window.location = '/';
    } else {
      if (storageUser) {
        setUser(storageUser);
      }
  
      if (token) {
        setToken(token);
      }
    }
  });

  return (
    <div className="wrapper antialiased overflow-auto">
      <AnimatePresence mode="wait" initial={false}>
        <Router>
          <Header key="header" />
          <div key="body">
            <Switch location={location} key={location.pathname}>
              {_.map(routes, (route) => {
                if (route.protected) {
                  return (
                    <ProtectedRoute
                      key={route.id}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                }
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </div>
          <Footer key="footer" />
        </Router>
        <Notifications key="notif" />
        <MenuSideBar />

        <motion.div
          initial="visible"
          animate="hidden"
          exit="visible"
          className={`${initializing ? 'fixed' : 'hidden'} bg-gray-800 inset-0 bg-opacity-75 z-20`}
        >
          <div className="loading-screen loading-screen-full-white">
            <Spinner className="text-accentColor" big />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default App;
