import React from 'react';
import { Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../loader/Loader';

function PopconfirmButton({
  disabled,
  title,
  description,
  okText,
  cancelText,
  onConfirm,
  onCancel,
  className,
  loading,
  text,
  loaderColor,
  textColor,
  borderColor,
  icon
}) {
  const borderClassNames = borderColor ? `border ${borderColor}` : ''
  return (
    <Popconfirm
      title={title}
      description={description}
      okText={okText}
      cancelText={cancelText}
      disabled={disabled}
      onConfirm={() => {
        if (!loading && onConfirm) {
          onConfirm();
        }
      }}
      onCancel={onCancel}
    >
      <div
        disabled={disabled}
        className={`${className} ${borderClassNames} cursor-pointer hover:scale-110 inline-flex text-sm h-10 flex justify-center items-center text-center disabled:opacity-25 text-sm transition duration-500 ease-in-out font-semibold rounded-full`}
      >
        {loading ? (
          <Loader color={loaderColor} />
        ) : (
          <span className={`${textColor} mx-2`}>
            {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
            {text}
          </span>
        )}
      </div>
    </Popconfirm>
  );
}

export default PopconfirmButton;
