import { atom } from 'recoil';

export const notificationsAtom = atom({
  key: 'notifications',
  default: [],
});

export const userAtom = atom({
  key: 'user',
  default: null,
});

export const isLoggingInAtom = atom({
  key: 'isLoggingIn',
  default: false,
});

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

export const alertAtom = atom({
  key: 'alert',
  default: {
    show: false,
    title: '',
    content: '',
    onClick: null,
  },
});

export const stateAtom = atom({
  key: 'currentState',
  default: null,
});

export const menuSideBarAtom = atom({
  key: 'menuSideBar',
  default: false,
});

export const mainPageInitializeAtom = atom({
  key: 'mainPageInitialize',
  default: true,
});

export const userPrivilagesAtom = atom({
  key: 'userPrivilages',
  default: { },
});

export const userOperatorsAtom = atom({
  key: 'userOperators',
  default: []
});
